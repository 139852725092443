import moment from 'moment-timezone';

// Function to format a date in CST
export const formatDateForDisplay = (date: Date | string | null | undefined, outputFormat: string) => {
    if (!date) {
        return ''; // Return an empty string or a default value if the date is null or undefined
    }
    // Define possible input formats
    const inputFormats = [
        moment.ISO_8601, // ISO 8601 format
        'YYYY-MM-DDTHH:mm:ss.SSSZ', // ISO 8601 with milliseconds
        'YYYY-MM-DDTHH:mm:ssZ', // ISO 8601 without milliseconds
        'YYYY-MM-DDTHH:mm:ss', // Date and time
        'YYYY-MM-DD', // Date only
        'MM/DD/YYYY', // US date format
        'MM-DD-YYYY', // US date format with dashes
        'DD/MM/YYYY', // European date format
        'DD-MM-YYYY', // European date format with dashes
        'MM/DD/YYYY h:mm:ss:SSS A', // US date format with time and milliseconds
        'MM/DD/YY h:mm A', // US date format with time
    ];

    const dateMoment = moment.tz(typeof date === 'string' ? date : date.toISOString(), inputFormats, 'America/Chicago');
    return dateMoment.isValid() ? dateMoment.format(outputFormat) : '';
};

// Function to set a date in CST
export const setDateInCST = (date: Date | string, format: string) => {
    const dateMoment = moment.tz(date, 'America/Chicago');
    return dateMoment.isValid() ? dateMoment.format(format) : '';
};