import React, { useState, useEffect, useMemo } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import _, { concat } from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import moment from 'moment'
import AdminDashboard from '../adminDashboard/adminDashboard';
import * as XLSX from 'xlsx'
import Modal from 'react-modal';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import { toast } from 'react-toastify';
import { ReviewStatus } from '../../utils/statusConstantValues';
import Cookies from 'js-cookie';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import { debounce } from 'lodash';
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';

export default function ContractReportInventory() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isFilesGridLoading, setIsFilesGridLoading] = useState(false)
    const gridStyle = { minHeight: 622, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)
    const ReportInventoryService = useReportInventoryService();
    const { getMasterTableLookupValueData, getMasterTableLookupData, getOrganizationUnitsTableLookupData } = useLooksUpData();
    const userType = sessionStorage.getItem('signedInUserType');
    let [inventoryReportResponseList, setInventoryReportResponseList] = useState<InventoryReportLIst[]>([]);
    const [filteredReportResponseList, setFilteredReportResponseList] = useState<InventoryReportLIst[]>([]);
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [submissionModeList, setSubmissionModeList] = useState<any>([])
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [reviewStatusList, setReviewStatusList] = useState<any>([])
    const [loggedInUserPlan, setLoggedInUserPlan] = useState<any>([])
    const { checkAccess } = useAccessControl();
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_SUBMSN_STATUS_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_REVIEW_STATUS_LKPCD');
                const mcSubmisstionMode = filterAndMapData('AD', 'MC_PROGRAM_REPORT', 'REPORT_SBMSN_MODE_LKPCD');
                const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');

                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setSubmissionModeList(mcSubmisstionMode);
                setReportTypeList(mcReportType);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const fetchReportInventoryData = async () => {
        try {
            setIsFilesGridLoading(true);
            const response = await ReportInventoryService.getReportInventory();
            const lookupDataResponse = await getMasterTableLookupData();
            if (response && lookupDataResponse) {

                let filteredMcPlan = lookupDataResponse.data.mc_plan.filter((item: { organizationUnitCode: string }) =>
                    item.organizationUnitCode === loggedInUserDetails.organizationUnitCode
                )
                setLoggedInUserPlan(filteredMcPlan[0])

                if (!accounts?.[0]?.localAccountId) {
                    const filteredData = response.data.filter((item: InventoryReportLIst) => {
                        // return item.plan_name && item.plan_name.toLowerCase().includes('molina');

                        return item.plan_id === filteredMcPlan[0]?.planId;
                    });

                    // Sort the filtered data
                    const sortedData = filteredData.sort((a: any, b: any) => {
                        const dateA = a.latest_due_date ? new Date(a.latest_due_date) : new Date(0);
                        const dateB = b.latest_due_date ? new Date(b.latest_due_date) : new Date(0);
                        const periodEndDateA = a.period_end_date ? new Date(a.period_end_date) : new Date(0);
                        const periodEndDateB = b.period_end_date ? new Date(b.period_end_date) : new Date(0);
                    
                        if (dateA.getTime() !== dateB.getTime()) {
                            return dateB.getTime() - dateA.getTime(); // Sort by latest_due_date in descending order
                        }
                        if (periodEndDateA.getTime() !== periodEndDateB.getTime()) {
                            return periodEndDateB.getTime() - periodEndDateA.getTime(); // Sort by period_end_date in descending order
                        }
                        if (a.plan_name && b.plan_name && a.plan_name !== b.plan_name) {
                            return a.plan_name.localeCompare(b.plan_name); // Sort by plan_name in ascending order
                        }
                        if (a.report_name && b.report_name) {
                            return a.report_name.localeCompare(b.report_name); // Sort by report_name in ascending order
                        }
                        return 0; // If all values are equal or null
                    });
                    

                    setInventoryReportResponseList(sortedData);
                } else {
                    const sortedData = response.data.sort((a: any, b: any) => {
                        const dateA = a.latest_due_date ? new Date(a.latest_due_date) : new Date(0);
                        const dateB = b.latest_due_date ? new Date(b.latest_due_date) : new Date(0);
                        const periodEndDateA = a.period_end_date ? new Date(a.period_end_date) : new Date(0);
                        const periodEndDateB = b.period_end_date ? new Date(b.period_end_date) : new Date(0);
                    
                        if (dateA.getTime() !== dateB.getTime()) {
                            return dateB.getTime() - dateA.getTime(); // Sort by latest_due_date in descending order
                        }
                        if (periodEndDateA.getTime() !== periodEndDateB.getTime()) {
                            return periodEndDateB.getTime() - periodEndDateA.getTime(); // Sort by period_end_date in descending order
                        }
                        if (a.plan_name && b.plan_name && a.plan_name !== b.plan_name) {
                            return a.plan_name.localeCompare(b.plan_name); // Sort by plan_name in ascending order
                        }
                        if (a.report_name && b.report_name) {
                            return a.report_name.localeCompare(b.report_name); // Sort by report_name in ascending order
                        }
                        return 0; // If all values are equal or null
                    });
                    
                    setInventoryReportResponseList(sortedData);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsFilesGridLoading(false);
        }
    };

    const fetchOrganizationUnitsTableLookupData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);

            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchGetMasterTableLookupValueData(), fetchOrganizationUnitsTableLookupData(), fetchReportInventoryData()]);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingAfStatusLkpcd = (data: any) => getMatchingDescription(afStatusLKPCDList, data);
    const matchingSubmissionModelLkpcd = (data: any) => getMatchingDescription(submissionModeList, data);
    const matchingReportingTypeLkpcd = (data: any) => getMatchingDescription(reportTypeList, data);

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode.includes(data));
        return matchedItem?.organizationUnitName || data;
    };
    const CustomCellRenderer = ({ value }: { value: string }) => (
        <div style={{ width: 'max-content', whiteSpace: 'nowrap' }}>{value}</div>
    );

    interface InventoryReportLIst {
        mct_report_instance_sid: string;
        plan_id: string;
        program_report_sid: string;
        period_begin_date: string;
        period_end_date: string;
        latest_due_date: string;
        original_due_date: string;
        current_submission_number: string;
        last_submission_status: string;
        last_submission_date: string;
        last_validation_status: string;
        last_review_status: string;
        notes_summary: string;
        oprtnl_flag: string;
        created_by: string;
        created_date: string;
        updated_by: string;
        updated_date: string;
        plan_name: string;
        report_name: string;
        start_reference_number: string;
        reporting_type_lkpcd: string;
        report_sbmsn_mode_lkpcd: string;
        due_date_frequency_lkpcd: string
        review_group_list: string
    };

    const CustomCellColorRenderer = (value: any) => {
        let textColor = 'black';
        if (value) {
            switch (value) {
                case ReviewStatus.IN_INITIAL_VALIDATION:
                    textColor = 'orange';
                    break;
                case ReviewStatus.INITIAL_VALIDATION_FAILED:
                    textColor = 'red';
                    break;
                case ReviewStatus.ADDITIONAL_INFO_REQUESTED:
                    textColor = 'orange';
                    break;
                case ReviewStatus.ADDITIONAL_INFO_SUBMITTED:
                    textColor = 'orange';
                    break;
                case ReviewStatus.IN_REVIEW:
                    textColor = 'orange';
                    break;
                case ReviewStatus.ACCEPTED:
                    textColor = 'green';
                    break;
                case ReviewStatus.REJECTED:
                    textColor = 'red';
                    break;
                default:
                    textColor = 'black';
            }

            return (
                <div style={{ color: textColor }}>
                    {matchingAfStatusLkpcd(value)}
                </div>
            );
        }
    }

    const contractReportsHeaders: any[] = [
        {
            name: 'plan_name',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ value }: any) => (
                <div title={value}>
                    {value}
                </div>
            ),
        },
        {
            name: 'report_name',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'start_reference_number',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Ref No.
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            // maxWidth: 300,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'due_date_frequency_lkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Frequency
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            maxWidth: 200,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: InventoryReportLIst }) => {
                return matchingFrequencyLkpcd(data.due_date_frequency_lkpcd);
            },
        },
        {
            name: 'period',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Period
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 180,
            maxWidth: 250,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: InventoryReportLIst }) => {
                const beginDate = formatDateForDisplay(data.period_begin_date,'MM/DD/YYYY');
                const endDate = formatDateForDisplay(data.period_end_date,'MM/DD/YYYY');

                return `${beginDate} - ${endDate}`;
            },
        },
        {
            name: 'latest_due_date',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Due Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 60,
            maxWidth: 150,
            render: ({ value }: any) => {
                // if (value) {
                //     const date = formatDateForDisplay(value);
                //     return date.isValid() ? date.format('MM/DD/YYYY') : '';
                // }
                // return '';
                return formatDateForDisplay(value,'MM/DD/YYYY');
            },
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'last_submission_status',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission Status
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 90,
            maxWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: InventoryReportLIst }) => {
                const value = matchingBusinessStatusLkpcd(data.last_submission_status);
                return (
                    <div title={value}>
                        {value}
                    </div>
                );
            },
        },
        {
            name: 'last_submission_date',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            maxWidth: 150,
            render: ({ value }: any) => {
                if (value) {
                    // const date = formatDateForDisplay(value);
                    // return date.isValid() ? date.format('M/D/YY h:mm A') : '';
                    return formatDateForDisplay(value,'MM/DD/YY h:mm A');
                }
                return '';
            },
            cellRenderer: CustomCellRenderer,
        },
        {
            name: 'last_review_status',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Review Status
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 150,
            render: ({ data }: { data: InventoryReportLIst }) => {
                return CustomCellColorRenderer(data.last_review_status);
            }
        },
        {
            name: 'updated_date',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            maxWidth: 200,
            render: ({ value }: any) => {
                if (value) {
                    // const date = formatDateForDisplay(value);
                    const formattedDate = formatDateForDisplay(value,'M/D/YY h:mm A');
                    return (
                        <div title={formattedDate}>
                            {formattedDate}
                        </div>
                    );
                }
                return '';
            },
            cellRenderer: CustomCellRenderer
        }
    ]

    const mapLookupValues = (item: any) => {
        return {
            ...item,
            due_date_frequency_lkpcd: matchingFrequencyLkpcd(item.due_date_frequency_lkpcd),
            last_submission_status: matchingBusinessStatusLkpcd(item.last_submission_status),
            last_review_status: matchingAfStatusLkpcd(item.last_review_status),
            period: `${formatDateForDisplay(item.period_begin_date,'MM/DD/YYYY')} - ${formatDateForDisplay(item.period_end_date,'MM/DD/YYYY')}`,
            latest_due_date: formatDateForDisplay(item.latest_due_date,'MM/DD/YYYY'),
            last_submission_date: formatDateForDisplay(item.last_submission_date,'M/D/YY h:mm A'),
            updated_date: formatDateForDisplay(item.updated_date,'M/D/YY h:mm A'),
        };
    };
    const mappedData = filteredReportResponseList.map(mapLookupValues);

    type UploadFile = {
        id: string;
        submissionDate: string;
        submission: string;
        name: string;
        type: string;
        mcoViewable?: string;
    };

    interface ContractReport {
        referenceNumber: string;
        mcoName: string;
        reportName: string;
        frequency: string;
        reportType?: string;
        startDate: string;
        endDate: string;
        channel: string;
        submissionDate: string;
        period: string;
        dueDate: string;
        currentSubmission: string;
        submissinStatus: string;
        reviewStatus: string;
        lastUpdatedBy: string;
        lastUpdatedDate: string;
        file?: string;
        uploadFiles?: UploadFile[];
        reviewNumber?: string;
    };

    const serachDropdownChoices1 = [
        {
            label: 'Submission Number',
            value: 'current_submission_number',
        },
        {
            label: 'Report Type',
            value: 'reporting_type_lkpcd',
        },
        {
            label: 'Submission Channel',
            value: 'report_sbmsn_mode_lkpcd',
        },
        {
            label: 'Reviewers Group',
            value: 'review_group_list',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'Submission Number',
            value: 'current_submission_number',
        },
        {
            label: 'Report Type',
            value: 'reporting_type_lkpcd',
        },
        {
            label: 'Submission Channel',
            value: 'report_sbmsn_mode_lkpcd',
        },
        {
            label: 'Reviewers Group',
            value: 'review_group_list',
        }
    ]

    const filteredSerachDropdownChoices1 = isNonStateUser
        ? serachDropdownChoices1.filter(option => option.value === 'current_submission_number' ||
            option.value === 'reporting_type_lkpcd' || option.value === 'report_sbmsn_mode_lkpcd') : serachDropdownChoices1;

    const filteredSerachDropdownChoices2 = isNonStateUser
        ? serachDropdownChoices2.filter(option => option.value === 'current_submission_number' ||
            option.value === 'reporting_type_lkpcd' || option.value === 'report_sbmsn_mode_lkpcd') : serachDropdownChoices2

    const filterValue = [
        { name: 'plan_name', operator: 'contains', type: 'string', value: '' },
        { name: 'report_name', operator: 'contains', type: 'string', value: '' },
        { name: 'start_reference_number', operator: 'contains', type: 'string', value: '' },
        { name: 'due_date_frequency_lkpcd', operator: 'contains', type: 'string', value: '' },
        { name: 'period', operator: 'contains', type: 'string', value: '' },
        { name: 'latest_due_date', operator: 'contains', type: 'string', value: '' },
        { name: 'currentSubmission', operator: 'contains', type: 'string', value: '' },
        { name: 'startDate', operator: 'contains', type: 'string', value: '' },
        { name: 'endDate', operator: 'contains', type: 'string', value: '' },
        { name: 'channel', operator: 'contains', type: 'string', value: '' },
        { name: 'last_submission_date', operator: 'contains', type: 'string', value: '' },
        { name: 'last_submission_status', operator: 'contains', type: 'string', value: '' },
        { name: 'last_review_status', operator: 'contains', type: 'string', value: '' },
        { name: 'updated_date', operator: 'contains', type: 'string', value: '' },
    ]
    const [selectedOption1, setSelectedOption1] = useState<keyof InventoryReportLIst | null>(null);
    const [selectedOption2, setSelectedOption2] = useState<keyof InventoryReportLIst | null>(null);
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setFilteredReportResponseList(inventoryReportResponseList);
    }, [inventoryReportResponseList]);

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter1Text('');
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter2Text('');
    }

    function handleFilterText1(event: any) {
        const { value } = event.target;
        setFilter1Text(value);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        setFilter1Text('');
        setFilter2Text('');
        setSelectedOption1(null);
        setSelectedOption2(null);
        setFilteredReportResponseList(inventoryReportResponseList);
    }

    function handleSearch() {
        let filter1Value: string | number | null = filter1Text;
        let filter2Value: string | number | null = filter2Text;

        if (selectedOption1 === 'review_group_list') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.organizationUnitCode : filter1Text;
        } else if (selectedOption1 === 'reporting_type_lkpcd') {
            const matchingValue = reportTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter1Text;
        } else if (selectedOption1 === 'report_sbmsn_mode_lkpcd') {
            const matchingValue = submissionModeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter1Text;
        } else if (selectedOption1 === 'current_submission_number') {
            filter1Value = filter1Text ? parseInt(filter1Text, 10) : null;
        }

        if (selectedOption2 === 'review_group_list') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.organizationUnitCode : filter2Text;
        } else if (selectedOption2 === 'reporting_type_lkpcd') {
            const matchingValue = reportTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter2Text;
        } else if (selectedOption2 === 'report_sbmsn_mode_lkpcd') {
            const matchingValue = submissionModeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter2Text;
        } else if (selectedOption2 === 'current_submission_number') {
            filter2Value = filter2Text ? parseInt(filter2Text, 10) : null;
        }

        const filteredList = inventoryReportResponseList.filter(item => {
            const matchesOption1 = selectedOption1 === 'review_group_list'
                ? Array.isArray(item.review_group_list) && item.review_group_list.includes(filter1Value || '')
                : selectedOption1
                    ? item[selectedOption1 as keyof InventoryReportLIst] === filter1Value
                    : true;

            const matchesOption2 = selectedOption2 === 'review_group_list'
                ? Array.isArray(item.review_group_list) && item.review_group_list.includes(filter2Value || '')
                : selectedOption2
                    ? item[selectedOption2 as keyof InventoryReportLIst] === filter2Value
                    : true;

            return matchesOption1 && matchesOption2;
        });

        setFilteredReportResponseList(filteredList);
    }

    const handleAdhocOrNewReport = () => {
        history.push('/adhoc-or-new-report');
    }

    const handleRowClick = (data: any) => {
        if (!checkAccess('report_inventory_listpage', 'NOAC')) {
            history.push({
                pathname: '/contract-report-details',
                state: { recordData: data }
            });
        }
    }

    const [filterDataList, setFilterDataList] = useState<InventoryReportLIst[]>([]);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    const headers = [
        'MCO Name',
        'Report Name',
        'Ref No.',
        'Frequency',
        'Period',
        'Due Date',
        'Submission Status',
        'Submission Date',
        'Review Status',
        'Updated Date',
        'Review User Group',
        'Review User ID',
        'Review Date',
        'Submission Number',
        'Report Type',
        'Channel',
    ];

    const mcoheaders = [
        'MCO Name',
        'Report Name',
        'Ref No.',
        'Frequency',
        'Period',
        'Due Date',
        'Submission Status',
        'Submission Date',
        'Review Status',
        'Updated Date',
        'Submission Number',
        'Report Type',
        'Channel',
    ];

    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);

    useEffect(() => {
        if (isNonStateUser) {
            setSelectedHeaders(mcoheaders);
        } else {
            setSelectedHeaders(headers);
        }
    }, [userType]);

    // useEffect(() => {
    //     const filteredData = inventoryReportResponseList.filter((item: any) => {
    //         return filterValue.every(filter => {
    //             const { name, value } = filter;
    //             const itemValue = item[name];

    //             if (itemValue === null || itemValue === undefined || itemValue === '') {
    //                 return value === '';
    //             }
    //             else {
    //                 return itemValue.toLowerCase().includes(value.toLowerCase());
    //             }

    //         });
    //     });
    //     console.log('Filtered data:', filteredData);
    //     setFilterDataList(filteredData);
    // }, [filterValue]);

    const toggleModal = () => {
        setSelectedHeaders(isNonStateUser ? mcoheaders : headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = inventoryReportResponseList.map((item) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    'MCO Name': item.plan_name,
                    'Report Name': item.report_name,
                    'Ref No.': item.start_reference_number,
                    'Frequency': matchingFrequencyLkpcd(item.due_date_frequency_lkpcd),
                    'Period': `${formatDateForDisplay(item.period_begin_date,'MM/DD/YYYY')} - ${formatDateForDisplay(item.period_begin_date,'MM/DD/YYYY')}`,
                    'Due Date': item.latest_due_date,
                    'Submission Status': matchingBusinessStatusLkpcd(item.last_submission_status),
                    'Submission Date': item.last_submission_date ? formatDateForDisplay(item.last_submission_date,'MM/DD/YYYY') : '',
                    'Review Status': matchingAfStatusLkpcd(item.last_review_status),
                    'Updated Date': formatDateForDisplay(item.updated_date,'MM/DD/YYYY'),
                    'Review User Group': Array.isArray(item.review_group_list) ? item.review_group_list.map((groups: any) => getMatchingMcOrgUnit(groups)).join(', ') : getMatchingMcOrgUnit(item.review_group_list),
                    'Review User ID': item.updated_by,
                    'Review Date': item.updated_date,
                    'Submission Number': item.current_submission_number,
                    'Channel': matchingSubmissionModelLkpcd(item.report_sbmsn_mode_lkpcd),
                }[header];
            });
            return row;
        });

        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'report inventory list');
        XLSX.writeFile(wb, 'report_inventory_list.xlsx');
        setIsExportModalOpen(false);
    };


    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Report Inventory" />
                </div>
            </div>

            <div className="form-layout">

                <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices1}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="filterCriteria1"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption1 ? selectedOption1 : ''}
                            onChange={handleFilterChange1}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 1"
                            value={filter1Text || ''}
                            onChange={handleFilterText1}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices2}
                            emptyChoiceLabel="Select Filter Criteria 2"
                            name="filterCriteria2"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption2 ? selectedOption2 : ''}
                            onChange={handleFilterChange2}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 2"
                            value={filter2Text || ''}
                            onChange={handleFilterText2}
                            small={true}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}
                        />
                    </div>
                    <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                            disabled={!inventoryReportResponseList || inventoryReportResponseList.length === 0 ||
                                checkAccess('report_inventory_listpage', 'NOAC') || checkAccess('report_inventory_listpage', 'VWNA')}
                        >
                            Export to Excel
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={handleAdhocOrNewReport}
                            disabled={checkAccess('add_inventory_report', 'NOAC')}
                        >
                            Add
                        </Button>

                    </div>
                </div>
                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Headers for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === (isNonStateUser ? mcoheaders.length : headers.length)}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {(isNonStateUser ? mcoheaders : headers).map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0}
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>

                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={contractReportsHeaders}
                            dataSource={mappedData}
                            sortable={true}
                            // onFilterValueChange={handleFilterChangeGrid}
                            defaultLimit={10}
                            defaultFilterValue={filterValue}
                            rowHeight={50}
                            onRowClick={({ data }) => handleRowClick(data)}
                            className="pointer-cursor-row"
                            loading={isFilesGridLoading}
                            enableColumnAutosize={true}
                            handle={setGridRef}
                        />

                    </>
                }
            </div>
            <Footer />
        </>
    );
}