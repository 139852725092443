import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import _, { concat } from 'lodash'
import './sftp-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import moment from 'moment'
import AdminDashboard from '../adminDashboard/adminDashboard';
import * as XLSX from 'xlsx'
import Modal from 'react-modal';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import { MdMargin } from 'react-icons/md';
import Cookies from 'js-cookie';

export default function UploadedSFTPReportsHomePage() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const gridStyle = { minHeight: 622, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";
    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    const CustomCellRenderer = ({ value }: { value: string }) => (
        <div style={{ width: 'max-content', whiteSpace: 'nowrap' }}>{value}</div>
    );

    const contractReportsHeaders: any[] = [

        {
            name: 'sftpUploadDatetime',
            header:
                (
                    <div className='grid-header-custom-style'>
                        SFTP Upload Datetime
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'fileName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        File Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'mcoName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'associatedReportName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Associated Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'frequency',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Frequency
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'period',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Period
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'dueDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Due Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            maxWidth: 200,
            render: ({ value }: any) => {
                if (value) {
                    const date = moment.utc(value);
                    return date.isValid() ? date.format('MM/DD/YYYY') : '';
                }
                return '';
            },
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'currentSubmission',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission #
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 90,
            maxWidth: 150,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'submissinStatus',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission Status
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 90,
            maxWidth: 150,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'lastUpdatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            maxWidth: 200,
            render: ({ value }: any) => {
                if (value) {
                    const date = moment.utc(value);
                    return date.isValid() ? date.format('M/D/YY h:mm A') : '';
                }
                return '';
            },
            cellRenderer: CustomCellRenderer
        }
    ]

    type UploadFile = {
        id: string;
        name: string;
        type: string;
    };

    interface UploadedSFTPReport {
        sftpUploadDatetime: string;
        fileName: string;
        mcoName: string;
        associatedReportName: string;
        frequency: string;
        period: string;
        dueDate: string;
        currentSubmission: string;
        submissinStatus: string;
        lastUpdatedDate: string;
        lastUpdatedBy?: string;
        uploadFiles?: UploadFile;
    };

    const initialData: UploadedSFTPReport[] = [
        {
            sftpUploadDatetime: '08/06/2024  11:03:01 AM',
            fileName: 'AppealsLog_MCH_2024_07.XLS',
            mcoName: 'Molina',
            associatedReportName: 'Appeals Log',
            frequency: 'Monthly',
            period: '07/01/24-07/31/24',
            dueDate: '08/15/2024',
            currentSubmission: '1',
            submissinStatus: 'Submitted',
            lastUpdatedDate: '08/07/2024  12:05:55 AM',
            lastUpdatedBy: 'Admin',
            uploadFiles:
                { id: '1', name: 'AppealsLog_MCH_2024_07.XLS', type: 'Main Report' },
        },

        {
            sftpUploadDatetime: '08/05/2024  11:03:01 AM',
            fileName: 'AppealsLog_NTC_2024_07.XLS',
            mcoName: 'Nebraska Total Care',
            associatedReportName: 'Appeals Log',
            frequency: 'Monthly',
            period: '07/01/24-07/31/24',
            dueDate: '08/15/2024',
            currentSubmission: '1',
            submissinStatus: 'Submitted',
            lastUpdatedDate: '8/6/2024  12:05:55 AM',
            lastUpdatedBy: 'Admin',
            uploadFiles:
                { id: '2', name: 'AppealsLog_NTC_2024_07.XLS', type: 'Main Report' },
        },

        {
            sftpUploadDatetime: '8/5/2024  6:31:09 AM',
            fileName: 'AppealsLog_UHC_2024_07.XLS',
            mcoName: 'United Health Care',
            associatedReportName: 'Appeals Log',
            frequency: 'Monthly',
            period: '07/01/24-07/31/24',
            dueDate: '08/15/2024',
            currentSubmission: '1',
            submissinStatus: 'Submitted',
            lastUpdatedDate: '8/6/2024  12:05:55 AM',
            lastUpdatedBy: 'Admin',
            uploadFiles:
                { id: '3', name: 'AppealsLog_UHC_2024_07.XLS', type: 'Main Report' },
        },
        {
            sftpUploadDatetime: '8/5/2024  6:31:09 AM',
            fileName: 'CareManagementLog_MHC_2024_07',
            mcoName: 'Molina',
            associatedReportName: '',
            frequency: '',
            period: '',
            dueDate: '',
            currentSubmission: '',
            submissinStatus: '',
            lastUpdatedDate: '8/6/2024  12:05:55 AM',
            lastUpdatedBy: 'Admin',
            uploadFiles:
                { id: '4', name: 'CareManagementLog_MHC_2024_07', type: 'Main Report' },
        },
        {
            sftpUploadDatetime: '8/4/2024  11:23:45 PM',
            fileName: 'Bi-WeeklyTipsReport_NTC_2024_07_B2',
            mcoName: 'Nebraska Total Care',
            associatedReportName: 'Bi-Weekly Tips Report',
            frequency: 'Semi-Monthly',
            period: '07/16/24-07/31/24',
            dueDate: '08/01/2024',
            currentSubmission: '1',
            submissinStatus: '',
            lastUpdatedDate: '8/5/2024  12:05:55 AM',
            lastUpdatedBy: 'Admin',
            uploadFiles:
                { id: '5', name: 'Bi-WeeklyTipsReport_NTC_2024_07_B2', type: 'Main Report' },
        },
        {
            sftpUploadDatetime: '8/4/2024  11:03:01 AM',
            fileName: 'Bi-WeeklyTipsReport_UHC_2024_07_B2',
            mcoName: 'United Health Care',
            associatedReportName: 'Bi-Weekly Tips Report',
            frequency: 'Semi-Monthly',
            period: '07/16/24-07/31/24',
            dueDate: '08/01/2024',
            currentSubmission: '1',
            submissinStatus: 'Submitted',
            lastUpdatedDate: '8/5/2024  12:05:55 AM',
            lastUpdatedBy: 'Admin',
            uploadFiles:
                { id: '6', name: 'Bi-WeeklyTipsReport_UHC_2024_07_B2', type: 'Main Report' },
        },

        {
            sftpUploadDatetime: '8/1/2024  11:03:01 AM',
            fileName: 'EVVKPIHomeHome_XXX_2024_07',
            mcoName: '',
            associatedReportName: '',
            frequency: 'Monthly',
            period: '',
            dueDate: '07/15/2024',
            currentSubmission: '',
            submissinStatus: '',
            lastUpdatedDate: '',
            lastUpdatedBy: 'Admin',
            uploadFiles:
                { id: '10', name: 'EVVKPIHomeHome_XXX_2024_07', type: 'Main Report' },
        },
    ];

    let [filterData, setFilterData] = useState<UploadedSFTPReport[]>([]);

    useEffect(() => {
        setIsLoading(true);
        const storedData = sessionStorage.getItem('uploadNewReportData');
        let combinedData = Array.isArray(initialData) ? [...initialData] : [];
        const parsedData = JSON.parse(storedData || '[]');

        try {
            if (userType) {
                if (Array.isArray(parsedData)) {
                    const combinedData = initialData.concat(parsedData);
                    const filteredData = combinedData.filter(item => item.mcoName.toLowerCase().includes('molina'));
                    setFilterData(filteredData);
                    console.log('parsedData:', filterData);

                }
            } else {
                combinedData = combinedData.concat(parsedData);
                setFilterData(combinedData);
            }
        } catch (error) {
            console.error('Error parsing contract reports from local storage:', error);
        }
        setIsLoading(false);
    }, []);

    const serachDropdownChoices1 = [
        {
            label: 'Link Flag',
            value: 'linkFlag',
        },
        {
            label: 'Reporting Year',
            value: 'reportingYear',
        },
        {
            label: 'Report Type',
            value: 'reportType',
        },
        {
            label: 'Channel',
            value: 'channel',
        },
        {
            label: 'Reference #',
            value: 'referenceNumber',
        },
        {
            label: 'Exempt-able',
            value: 'exemptable',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'Link Flag',
            value: 'linkFlag',
        },
        {
            label: 'Reporting Year',
            value: 'reportingYear',
        },
        {
            label: 'Report Type',
            value: 'reportType',
        },
        {
            label: 'Channel',
            value: 'channel',
        },
        {
            label: 'Reference #',
            value: 'referenceNumber',
        },
        {
            label: 'Exempt-able',
            value: 'exemptable',
        }
    ]

    const filteredSerachDropdownChoices1 = isNonStateUser
        ? serachDropdownChoices1.filter(option => option.value === 'submissionNumber' ||
            option.value === 'reportType' || option.value === 'channel' || option.value === 'referenceNumber' ||
            option.value === 'exemptable'
        )
        : serachDropdownChoices1;

    const filteredSerachDropdownChoices2 = isNonStateUser
        ? serachDropdownChoices2.filter(option => option.value === 'submissionNumber' ||
            option.value === 'reportType' || option.value === 'channel' || option.value === 'referenceNumber' ||
            option.value === 'exemptable')
        : serachDropdownChoices2

    const filterValue = [
        { name: 'sftpUploadDatetime', operator: 'contains', type: 'string', value: '' },
        { name: 'fileName', operator: 'contains', type: 'string', value: '' },
        { name: 'mcoName', operator: 'contains', type: 'string', value: '' },
        { name: 'associatedReportName', operator: 'contains', type: 'string', value: '' },
        { name: 'frequency', operator: 'contains', type: 'string', value: '' },
        { name: 'period', operator: 'contains', type: 'string', value: '' },
        { name: 'dueDate', operator: 'contains', type: 'string', value: '' },
        { name: 'currentSubmission', operator: 'contains', type: 'string', value: '' },
        { name: 'submissinStatus', operator: 'contains', type: 'string', value: '' },
        { name: 'lastUpdatedDate', operator: 'contains', type: 'string', value: '' },
    ]
    const [selectedOption1, setSelectedOption1] = useState<any>(null);
    const [selectedOption2, setSelectedOption2] = useState<any>(null);
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText1(event: any) {
        const enteredValue = event.target.value;
        setFilter1Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        setFilter1Text(null);
        setFilter2Text(null);
        setSelectedOption1(null);
        setSelectedOption2(null);
        // Ensure getAllBusinessAppList is defined and used correctly
        // getAllBusinessAppList(true, currentStatusDropdownState);
    }

    function handleSearch() {
        // savedFilters.businessApplicationMain = {
        //   selectedOption1,
        //   selectedOption2,
        //   filter1Text,
        //   filter2Text,
        // } as any
        // getAllBusinessAppList(false, currentStatusDropdownState)
    }

    const handleRowClick = (data: any) => {
        history.push({
            pathname: '/sftp-report-details',
            state: { recordData: data }
        });
    }

    const [filterDataList, setFilterDataList] = useState<any[]>([]);

    // useEffect(() => {
    //     const filteredData = filterData.filter((item: any) => {
    //         return filterValue.every(filter => {
    //             const { name, value } = filter;
    //             const itemValue = item[name];

    //             if (name === 'status') {
    //                 if (value === null || value === '' || value === 'undefined') {
    //                     return true;
    //                 } else {
    //                     return itemValue.toLowerCase() === value.toLowerCase();
    //                 }
    //             } else if (itemValue === null || itemValue === undefined || itemValue === '') {
    //                 return value === '';
    //             } else if (value === 'Active') {
    //                 return itemValue.toLowerCase() === value.toLowerCase();
    //             }
    //             else {
    //                 return itemValue.toLowerCase().includes(value.toLowerCase());
    //             }
    //         });
    //     });
    //     setFilterDataList(filteredData);
    // }, [filterData, filterValue]);

    const headers = [
        'SFTP Upload Datetime',
        'File Name',
        'MCO Name',
        'Associated Report Name',
        'Frequency',
        'Period',
        'Due Date',
        'Current Submission',
        'Submission Status',
        'Last Updated Date'
    ];


    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);
    const stripHtmlTags = (html: any) => {
        const tmp = document.createElement('div')
        tmp.innerHTML = html
        return tmp.textContent || tmp.innerText || ''
    }
    const toggleModal = () => {
        setSelectedHeaders(headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = filterData.map((item) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    'SFTP Upload Datetime': item.sftpUploadDatetime,
                    'File Name': item.fileName,
                    'MCO Name': item.mcoName,
                    'Associated Report Name': item.associatedReportName,
                    'Frequency': item.frequency,
                    'Period': item.period,
                    'Due Date': item.dueDate,
                    'Current Submission': item.currentSubmission,
                    'Submission Status': item.submissinStatus,
                    'Last Updated Date': item.lastUpdatedDate
                }[header]
            });
            return row;
        });

        // Create a worksheet
        // const firstRec = dataToExport[0]
        // dataToExport.unshift(firstRec)

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'uploaded sftp reports');
        XLSX.writeFile(wb, 'sftp_report_list.xlsx');
        setIsExportModalOpen(false);
    };


    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Uploaded SFTP Files Mapping to Reports" />
                </div>
            </div>

            <div className="form-layout">
                <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices1}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="filterCriteria1"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption1 ? selectedOption1 : ''}
                            onChange={handleFilterChange1}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 1"
                            value={filter1Text || ''}
                            onChange={handleFilterText1}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices2}
                            emptyChoiceLabel="Select Filter Criteria 2"
                            name="filterCriteria2"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption2 ? selectedOption2 : ''}
                            onChange={handleFilterChange2}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 2"
                            value={filter2Text || ''}
                            onChange={handleFilterText2}
                            small={true}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}
                        />
                    </div>
                    <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                            disabled={!filterData || filterData.length === 0}
                        >
                            Export to Excel
                        </Button>
                    </div>
                </div>
                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Fields for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === headers.length}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {headers.map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0}
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>

                <ReactDataGrid
                    licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                    idProperty="key"
                    style={gridStyle}
                    pagination
                    columns={contractReportsHeaders}
                    dataSource={filterData}
                    sortable={true}
                    // onFilterValueChange={handleFilterChangeGrid}
                    defaultLimit={10}
                    defaultFilterValue={filterValue}
                    rowHeight={50}
                    onRowClick={({ data }) => handleRowClick(data)}
                    className="pointer-cursor-row"
                    enableColumnAutosize={true}
                    handle={setGridRef}
                />
            </div>
            <Footer />
        </>
    );
}