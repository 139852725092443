import React, { useState, useEffect, useCallback } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _, { set } from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import moment from 'moment-timezone';
import Modal from 'react-modal';
import * as XLSX from 'xlsx'
import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa';
import { getTextFromShortCode } from '../../utils/textMappings';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { SubmissionStatus, ReviewStatus, ChooseActionsType } from '../../utils/statusConstantValues';
import { downloadFileToken } from "../../../src/utils/url-constant";
import { toast } from 'react-toastify';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Cookies from 'js-cookie';
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';

export default function ContractReportInventoryDetails() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isFilesGridLoading, setIsFilesGridLoading] = useState(false)
    const location = useLocation();
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [reportActivityList, setReportActivityList] = useState<any>([])
    const [mcReportList, setMcReportList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [reportActivityLKPCDList, setReportActivityLKPCDList] = useState<any>([])
    const [submissionModeList, setSubmissionModeList] = useState<any>([])
    const [uploadedFileSubmissionModeList, setUploadedFileSubmissionModeList] = useState<any>([])
    const [uploadedFileTypeKPCDList, setUploadedFileTypeKPCDList] = useState<any>([])
    const [uploadedErrorFileTypeKPCDList, setUploadedErrorFileTypeKPCDList] = useState<any>([])
    const userType = sessionStorage.getItem('signedInUserType');
    const ReportInventoryService = useReportInventoryService();
    const [uploadedFileList, setUploadedFileList] = useState<any>([])
    const { getMasterTableLookupValueData, getMasterTableLookupData, getOrganizationUnitsTableLookupData } = useLooksUpData();
    const [combinedNotes, setCombinedNotes] = useState('');
    const { checkAccess } = useAccessControl();
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);

    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";
    let envPrefix = process.env.REACT_APP_ENV_PREFIX || '';

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (groups: string | string[], table: string, column: string) => {
                    return data
                        .filter((dt: any) => groups.includes(dt.lkpcd_group) && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_SUBMSN_STATUS_LKPCD');
                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_REVIEW_STATUS_LKPCD');
                const mcSubmisstionMode = filterAndMapData('AD', 'MC_PROGRAM_REPORT', 'REPORT_SBMSN_MODE_LKPCD');
                const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');
                const mcReportActivityList = filterAndMapData('NG', 'MCT_REPORT_ACTIVITY', 'ACTIVITY_LKPCD');
                const mcUploadedFileSubmisstionMode = filterAndMapData('NG', 'MCT_REPORT_ATTACHMENT', 'SUBMISSION_MODE_LKPCD');
                const mcUploadedFileTypeList = filterAndMapData(['MC', 'OR', 'ST'], 'MCT_REPORT_ATTACHMENT', 'UPLOAD_TYPE_LKPCD');

                //for error file type
                const mcUploadedErrorFileTypeList = filterAndMapData('VL', 'MCT_REPORT_ATTACHMENT', 'UPLOAD_TYPE_LKPCD');

                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setSubmissionModeList(mcSubmisstionMode);
                setReportTypeList(mcReportType);
                setReportActivityLKPCDList(mcReportActivityList);
                setUploadedFileTypeKPCDList(mcUploadedFileTypeList)
                setUploadedErrorFileTypeKPCDList(mcUploadedErrorFileTypeList)
                setUploadedFileSubmissionModeList(mcUploadedFileSubmisstionMode);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcReportData = async () => {
        try {
            const response = await ReportInventoryService.getAllMcReports();
            if (response) {
                const { data } = response;
                const filterAndMapData = () => {
                    return data
                        .map((code: any) => ({
                            reportName: `${code.reportName}`,
                            reportDescription: code.reportDescription,
                        }));
                };
                setMcReportList(filterAndMapData);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getReportActivityList = async (id: any) => {
        try {
            const response = await ReportInventoryService.getReportActivityList(id);
            if (response) {
                const sortedData = response.data.sort((a: any, b: any) => {
                    const dateA = moment.tz(a.updatedDate, 'America/Chicago');
                    const dateB = moment.tz(b.updatedDate, 'America/Chicago');
                    return dateB.diff(dateA);
                });
                setReportActivityList(sortedData);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchUploadedFilesList = async (id: any) => {
        try {
            setIsLoading(true);
            setIsFilesGridLoading(true);
            const attachmentsResponse = await ReportInventoryService.mctReportAttachments(id);
            const blobFilesResponse = await ReportInventoryService.getUploadedFilesListBySid('mcofiles', 'NEMCIS-' + id);

            if (attachmentsResponse && blobFilesResponse) {
                const attachments = attachmentsResponse.data;
                const blobFiles = blobFilesResponse.data;

                const filteredAttachments = attachments.filter((attachment: any) => {
                    const isMcoSubmitterOrNonNddUser = Cookies.get('selectedProfileLabel') === 'MCO SUBMITTERS' || isNonStateUser;

                    // Exclude files with mcoViewableFlag == 'N' for MCO-Submitter and non-NDD users
                    if (isMcoSubmitterOrNonNddUser && attachment.mcoViewableFlag === 'N') {
                        return false;
                    }

                    return true;
                });

                // Combine the responses based on the id property
                const combinedResponse = filteredAttachments.map((attachment: any) => {
                    const matchingBlobFile = blobFiles.find((blobFile: any) => parseInt(blobFile.attachmentRecordId) === attachment.mctReportAttachmentSid);
                    return {
                        ...attachment,
                        ...matchingBlobFile
                    };
                });

                // Filter the combined response based on the presence of iv files
                // const ivFilesPresent = combinedResponse.some((item: any) => item.uploadTypeLkpcd === 'IV');
                // const filteredCombinedResponse = ivFilesPresent
                //     ? combinedResponse.filter((item: any) => item.uploadTypeLkpcd === 'IV')
                //     : combinedResponse;

                const sortedData = combinedResponse.sort((a: any, b: any) => {
                    const dateA = moment.tz(a.updatedDate, 'America/Chicago');
                    const dateB = moment.tz(b.updatedDate, 'America/Chicago');
                    return dateB.diff(dateA);
                });

                // if use filteredCombinedResponse values, only error files will be displayed
                setUploadedFileList(sortedData);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
            setIsFilesGridLoading(false);
        }
    };

    const mapApiResponseToLocalState = (apiData: any) => {
        return {
            mct_report_instance_sid: apiData.mctReportInstanceSid,
            plan_id: apiData.planId,
            program_report_sid: apiData.programReportSid,
            period_begin_date: apiData.periodBeginDate,
            period_end_date: apiData.periodEndDate,
            latest_due_date: apiData.latestDueDate,
            current_due_date: apiData.requestedDueDate,
            original_due_date: apiData.originalDueDate,
            accepeted_due_date: apiData.acceptedDueDate,
            current_submission_number: apiData.currentSubmissionNumber,
            last_submission_status: apiData.lastSubmsnStatusLkpcd,
            last_submission_date: apiData.lastSubmsnDate,
            last_validation_status: apiData.lastValidationStatusLkpcd,
            last_review_status: apiData.lastReviewStatusLkpcd,
            notes_summary: apiData.notesSummary,
            oprtnl_flag: apiData.oprtnlFlag,
            created_by: apiData.createdBy,
            created_date: apiData.createdDate,
            updated_by: apiData.updatedBy,
            updated_date: apiData.updatedDate,
            plan_name: (location.state as LocationState)?.recordData?.plan_name || apiData.planId,
            report_name: apiData.reportName,
            start_reference_number: apiData.stateReferenceNumber,
            uploadFiles: apiData.uploadFiles,
            reporting_type_lkpcd: apiData.reportingTypeLkpcd,
            report_sbmsn_mode_lkpcd: apiData.reportSbmsnModeLkpcd,
            due_date_frequency_lkpcd: apiData.due_date_frequency_lkpcd,
            actual_sbmsnmode_Lkpcd: apiData.actualSbmsnModeLkpcd,
            review_group_list: apiData.reviewGroupList
        };
    };

    const getMctreportinstanceDetailsById = async (id: any) => {
        try {
            const response = await ReportInventoryService.getMctreportinstanceDetailsById(id);

            if (response) {
                const { data } = response;

                let mergedData = { ...data };

                if (data?.programReportSid) {
                    const mcProgramResponse = await ReportInventoryService.getProgramReportByProgramReportSid(data?.programReportSid);
                    if (mcProgramResponse) {
                        mergedData = {
                            ...mergedData,
                            ...mcProgramResponse,
                            due_date_frequency_lkpcd: mcProgramResponse?.data?.dueDateFrequencyLkpcd,
                        };

                        const mappedData = mapApiResponseToLocalState(mergedData);
                        setRecordData(mappedData);
                    }
                } else {
                    const mappedData = mapApiResponseToLocalState(data);
                    setRecordData(mappedData);
                }

            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchOrganizationUnitsTableLookupData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);

            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const fetchPromises = [
                fetchGetMasterTableLookupValueData(),
                fetchGetMcReportData(),
                fetchOrganizationUnitsTableLookupData()
            ];

            if (recordData?.mct_report_instance_sid) {
                fetchPromises.push(getReportActivityList(recordData.mct_report_instance_sid));
                fetchPromises.push(fetchUploadedFilesList(recordData.mct_report_instance_sid));
                fetchPromises.push(getMctreportinstanceDetailsById(recordData.mct_report_instance_sid));
            }

            await Promise.all(fetchPromises);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    //returning the empty notes as well
    // useEffect(() => {
    //     if (reportActivityList && reportActivityList.length > 0) {
    //         const notes = reportActivityList.map((activity: any) => {
    //             const formattedDate = formatDateAndTime(activity.updatedDate);
    //             return `<p>${formattedDate} ${activity.updatedBy} - ${activity?.activityNotes?.replace(/<\/?p>/g, '')}</p>`;
    //         }).join('');
    //         setCombinedNotes(notes);
    //     }
    // }, [reportActivityList]);

    //returning only non empty notes record
    useEffect(() => {
        if (reportActivityList && reportActivityList.length > 0) {
            const notes = reportActivityList
                .filter((activity: any) => activity.activityNotes && activity.activityNotes.trim() !== '')
                .map((activity: any) => {
                    const formattedDate = formatDateAndTime(activity.updatedDate);
                    const updatedByValue = isNonStateUser ? maskUpdatedBy(activity.updatedBy) : activity.updatedBy;
                    return `<p>${formattedDate} ${updatedByValue} - ${activity?.activityNotes?.replace(/<\/?p>/g, '')}</p>`;
                }).join('');
            setCombinedNotes(notes);
        }
    }, [reportActivityList]);

    interface ContractReport {
        mct_report_instance_sid: string;
        plan_id: string;
        program_report_sid: string;
        period_begin_date: string;
        period_end_date: string;
        latest_due_date: string;
        accepeted_due_date: string;
        current_due_date: string;
        original_due_date: string;
        current_submission_number: string;
        last_submission_status: string;
        last_submission_date: string;
        last_validation_status: string;
        last_review_status: string;
        notes_summary: string;
        oprtnl_flag: string;
        created_by: string;
        created_date: string;
        updated_by: string;
        updated_date: string;
        plan_name: string;
        report_name: string;
        start_reference_number: string;
        uploadFiles: string;
        reporting_type_lkpcd: string;
        report_sbmsn_mode_lkpcd: string;
        due_date_frequency_lkpcd: string;
        actual_sbmsnmode_Lkpcd: string;
        review_group_list: string
    };

    interface LocationState {
        recordData?: ContractReport;
    }

    // const recordData = (location?.state as LocationState)?.recordData || null;
    const [recordData, setRecordData] = useState((location?.state as LocationState)?.recordData || null);

    function handleCancel() {
        history.goBack()
    }

    const handleReviewButton = (data: any) => {
        history.push({
            pathname: '/mco-review',
            state: { recordData: data }
        });
    }

    const handleMcoActionsButton = (data: any) => {
        history.push({
            pathname: '/mco-actions',
            state: { recordData: data }
        });
    }

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const getMatchingMcReportDescription = (data: any) => {
        const matchedItem = mcReportList.find((code: any) => code.reportName.includes(data));
        return matchedItem?.reportDescription || '';
    };

    const [viewableText, setViewableText] = useState<any[]>([
        { value: 'Y', description: 'Yes' },
        { value: 'N', description: 'No' },
    ]);

    const getMatchingYesOrNoValues = (data: string): string => {
        const matchedItem = viewableText.find((code) => code.value.toLowerCase() === data.toLowerCase());
        return matchedItem?.description || '';
    };

    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingAfStatusLkpcd = (data: any) => getMatchingDescription(afStatusLKPCDList, data);
    const matchingSubmissionModelLkpcd = (data: any) => getMatchingDescription(submissionModeList, data);
    const matchingReportingTypeLkpcd = (data: any) => getMatchingDescription(reportTypeList, data);
    const matchingreportActivityLKPCD = (data: any) => getMatchingDescription(reportActivityLKPCDList, data);
    const matchingUploadedFileTypeLKPCD = (data: any) => getMatchingDescription(uploadedFileTypeKPCDList, data);
    const matchingUploadedErrorFileTypeLKPCD = (data: any) => getMatchingDescription(uploadedErrorFileTypeKPCDList, data);
    const matchingUploadedFileSubmissionModelLkpcd = (data: any) => getMatchingDescription(uploadedFileSubmissionModeList, data);

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchedItem?.organizationUnitName || data;
    };

    interface ContractReviewHistory {
        dateAndTime: string;
        activity: string;
        submission: string;
        updatedBy: string;
    };

    const gridStyle = { minHeight: 340, width: '100%', border: '1px solid #dfe1e5' };

    const handleRowClick = (data: any) => {
        let path = '/mco-actions';
        const activity = data.activity;

        switch (activity) {
            case 'Scheduled for Submission':
            case 'Past due date':
            case 'Request for extension submitted':
            case 'Report Files submitted':
            case 'Rescheduled for resubmission':
            case 'Report Files resubmitted':
                path = '/mco-actions';
                break;
            case 'Request for extension rejected':
            case 'Reviewed and assigned to other review group':
            case 'Reviewed and rejected':
            case 'Report files validated for data quality':
            case 'Reviewed and accepted':
                path = '/mco-review';
                break;
            default:
                path = path;
                break;
        }

        history.push({
            pathname: path,
            state: {
                recordData,
                reviewHistoryData: data,
                detailsPageReferenceFalg: true,
            }
        });
    }

    const contractReportsHistoryHeaders: any[] = [
        {
            name: 'updatedDate',
            header: (
                <div className='grid-header-custom-style'>
                    Date and Time
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            maxWidth: 250,
            // maxWidth: 300,
            render: ({ data }: { data: any }) => {
                return formatDateAndTime(data.updatedDate);
            },
        },
        {
            name: 'activityLkpcd',
            header: (
                <div className='grid-header-custom-style'>
                    Activity
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            render: ({ data }: { data: any }) => {
                const value = matchingreportActivityLKPCD(data.activityLkpcd);
                return (
                    <div title={value}>
                        {value}
                    </div>
                );
            },

        },
        {
            name: 'submissionNumber',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission #
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            maxWidth: 150,
        },
        {
            name: 'submitFlag',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Activity Status
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            maxWidth: 150,
            render: ({ value }: any) => {
                switch (value) {
                    case 'Y':
                        return 'Completed';
                    case 'N':
                        return 'Pending';
                    default:
                        return '';
                }
            }
        },
        {
            name: 'updatedBy',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated By
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            render: ({ data }: any) => {
                return (
                    <span>
                        {isNonStateUser ? maskUpdatedBy(data?.updatedBy) : data?.updatedBy}
                    </span>
                );
            },
        },
        {
            name: 'action',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Action
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 130,
            render: ({ data }: any) => {
                return (
                    <Button
                        disabled={isNonStateUser}
                        className='okButton'
                        onClick={() => handleRowClick(data)}>
                        View
                    </Button>
                );
            },
        }
    ]

    const handleDownload = async (recordData: any, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        try {
            console.log('Starting file download...');
            const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mctReportInstanceSid, recordData?.attachmentRecordId);
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = downloadUrl;
            a.download = recordData?.fileName || 'error-details.json';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
            toast.success('File download successful.');
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Failed to download error details file. Please try again later.');
        }
    };

    const isDisabled = checkAccess('report_detail_page', 'VWNA') || checkAccess('report_detail_page', 'NOAC');

    const uploadedAttachmentsHeaders: any[] = [
        {
            name: 'updatedDate',
            header: (
                <div className='grid-header-custom-style'>
                    Submission Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            // maxWidth: 200,
            render: ({ data }: { data: any }) => {
                return formatDateAndTime(data.updatedDate);
            },
        },
        {
            name: 'mctActivityInstanceSid',
            header: (
                <div className='grid-header-custom-style'>
                    Submission #
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 90,
            maxWidth: 150,
            render: ({ data }: { data: any }) => {
                return getSubmissionNumber(data.mctActivityInstanceSid);
            },

        },
        {
            name: 'fileName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        File Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 300,
            // maxWidth: 350,
        },
        {
            name: 'uploadTypeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        File Type
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            // maxWidth: 150,
            render: ({ data }: { data: any }) => {
                if (data?.uploadTypeLkpcd === 'IV') {
                    return matchingUploadedErrorFileTypeLKPCD(data.uploadTypeLkpcd)
                } else {
                    return matchingUploadedFileTypeLKPCD(data.uploadTypeLkpcd)
                }
            },
        },
        {
            name: 'mcoViewableFlag',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO Viewable
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            // maxWidth: 200,
            render: ({ data }: { data: any }) => {
                return getMatchingYesOrNoValues(data.mcoViewableFlag)
            },
        },
        {
            name: 'action',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Download/View
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            // maxWidth: 200,
            render: ({ data }: { data: any }) => {

                const isJsonFile = data?.fileName?.endsWith('_error.json');

                return (
                    <div>
                        <a className={isDisabled ? 'disabled-row' : "file-link"}
                            onClick={isDisabled ? (e) => e.preventDefault() : (e) => handleDownload(data, e)}
                            href={isDisabled ? undefined : '#'}
                            target="_blank" rel="noopener noreferrer"
                            style={{ display: 'inline-flex', margin: '5px' }}
                            title="View File">
                            View File <FaExternalLinkAlt style={{ marginLeft: '5px' }} />
                        </a>
                        <span style={{ margin: '5px' }}>or</span>

                        {isJsonFile ? (
                            <a
                                className={isDisabled ? 'disabled-row' : 'file-link'}
                                onClick={isDisabled ? (e) => e.preventDefault() : (e) => handleDownload(data, e)}
                                href={isDisabled ? undefined : '#'}
                                download={isDisabled ? undefined : data?.fileName}
                                style={{ display: 'inline-flex', margin: '5px' }}
                                title="Download File"
                            >
                                Download <FaDownload style={{ marginLeft: '5px' }} />
                            </a>
                        ) : (
                            <a
                                className={isDisabled ? 'disabled-row' : 'file-link'}
                                onClick={isDisabled ? (e) => e.preventDefault() : (e) => handleDownload(data, e)}
                                href={isDisabled ? undefined : '#'}
                                download={isDisabled ? undefined : data?.fileName}
                                style={{ display: 'inline-flex', margin: '5px' }}
                                title="Download File"
                            >
                                Download <FaDownload style={{ marginLeft: '5px' }} />
                            </a>
                        )}
                    </div>
                );
            },
        }
    ]

    const reviewHistoryinitialData: ContractReviewHistory[] = [
        {
            dateAndTime: '7/24/2024  5:04:16 PM',
            activity: 'Reviewed and accepted',
            submission: '2',
            updatedBy: 'nfarm@nebraksa.gov',
        },
        {
            dateAndTime: '7/24/2024  10:11:16 PM',
            activity: 'Report files validated for data quality',
            submission: '2',
            updatedBy: 'System@nebraksa.gov',
        },
        {
            dateAndTime: '7/24/2024  10:11:16 PM',
            activity: 'Report Files resubmitted',
            submission: '2',
            updatedBy: 'Ajohn@ntp.org',
        },
        {
            dateAndTime: '7/24/2024  10:11:00 AM',
            activity: 'Rescheduled for resubmission',
            submission: '2',
            updatedBy: 'System@nebraska.gov',
        },
        {
            dateAndTime: '7/20/2024  4:53:22 PM',
            activity: 'Reviewed and rejected',
            submission: '1',
            updatedBy: 'nfarm@nebraksa.gov',
        },
        {
            dateAndTime: '7/20/2024  9:23:50 AM',
            activity: 'Reviewed and assigned to other review group',
            submission: '1',
            updatedBy: 'acaprio@nebraksa.gov',
        },
        {
            dateAndTime: '7/20/2024  8:00:00 AM',
            activity: 'Report files validated for data quality',
            submission: '1',
            updatedBy: 'System@nebraska.gov',
        },
        {
            dateAndTime: '7/20/2024  8:00:00 AM',
            activity: 'Report Files submitted',
            submission: '1',
            updatedBy: 'Ajohn@ntp.org',
        },
        {
            dateAndTime: '7/17/2024  9:20:00 AM',
            activity: 'Request for extension rejected',
            submission: '1',
            updatedBy: 'acaprio@nebraksa.gov',
        },
        {
            dateAndTime: '7/17/2024  9:00:00 AM',
            activity: 'Request for extension submitted',
            submission: '1',
            updatedBy: 'Ajohn@ntp.org',
        },
        {
            dateAndTime: '7/15/2024  9:00:00 AM',
            activity: 'Past due date',
            submission: '1',
            updatedBy: 'System@nebraska.gov',
        },
        {
            dateAndTime: '6/30/2024  12:00:00 AM',
            activity: 'Scheduled for Submission',
            submission: '1',
            updatedBy: 'System@nebraska.gov',
        },

    ];

    const submissionStatusDropdown = [
        { label: 'Scheduled', value: 'Scheduled' },
        { label: 'Resubmitted', value: 'Resubmitted' },
        { label: 'Past Due', value: 'Resubmission' },
        { label: 'Closed', value: 'Closed' },
        { label: 'Submitted', value: 'Submitted' },
        { label: 'Reopened', value: 'Reopened' },
    ];
    const submissionReviewDropdown = [
        { label: 'Accepted', value: 'Accepted' },
        { label: 'In Review', value: 'In Review' },
        { label: 'Reassigned', value: 'Reassigned' },
        { label: 'Rejected', value: 'Rejected' },

    ];

    let [reviewHistoryListDate, setReviewHistoryListDate] = useState<ContractReviewHistory[]>(recordData?.plan_name === 'Nebraksa Total Care' && recordData?.last_review_status === 'Accepted' ? reviewHistoryinitialData : []);

    const handleRichTextChangeNotes = () => {
    }
    function joinClasses(...classes: (string | undefined)[]): string {
        return classes.filter(Boolean).join(' ')
    }
    const wrapperClasses = joinClasses('usa-fieldset usa-form-group')

    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    // const formatDateForInput = (dateString: string) => {
    //     let parts;
    //     if (dateString.includes('/')) {
    //         parts = dateString.split('/');
    //         return `${parts[2]}-${parts[0]}-${parts[1]}`;
    //     } else if (dateString.includes('-')) {
    //         parts = dateString.split('-');
    //         return `${parts[0]}-${parts[1]}-${parts[2]}`;
    //     }
    //     return '';
    // };

    const formatDateForInput = (value: any) => {
        if (value) {
            const date = moment.utc(value);
            return date.isValid() ? date.format('MM/DD/YYYY') : '';
            // return formatDateForDisplay(value, 'MM/DD/YYYY');
        }
        return '';
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            // return formatDateForDisplay(value, 'MM/DD/YYYY h:mm:ss:SSS A');

            const date = moment.utc(value);
            return date.isValid() ? date.format('MM/DD/YYYY h:mm:ss:SSS A') : '';
        }
        return '';
    };

    const viewUploadedDocs = (event: any) => {
        const documentUrl = '/assets/docs/ScreenMockUp-ManagedCareCollabTool.pdf';
        window.open(documentUrl, '_blank');
    }

    const formattedSubmissionDate = recordData?.last_submission_date
        ? formatDateForDisplay(recordData.last_submission_date, 'MM/DD/YYYY hh:mm A')
        : '';
    const [fileValue, setFileValue] = useState<any[]>([]);

    useEffect(() => {
        if (recordData) {
            const uploadedFiles = Array.isArray(recordData.uploadFiles) ? recordData.uploadFiles : [];
            setFileValue(uploadedFiles);
        }
    }, [recordData]);

    const handleRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
    }

    const headers = [
        'Date & Time',
        'Activity',
        'Submission#',
        'Activity Status',
        'Updated By',
        // 'MCOs Action',
        // 'Reviewer Decision',
        // 'Previous Due Date',
        // 'New Due Date',
        'MCO state note',
        // 'Internal Note'
    ];

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);
    const toggleModal = () => {
        setSelectedHeaders(headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = reportActivityList.map((item: any) => {

            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    'Date & Time': formatDateAndTime(item?.updatedDate),
                    'Activity': matchingreportActivityLKPCD(item?.activityLkpcd),
                    'Submission#': getSubmissionNumber(item?.mctReportActivitySid),
                    'Activity Status': item?.submitFlag === 'Y' ? 'Completed' : 'Pending',
                    'MCO state note': item?.activityNotes,
                    'Updated By': item?.updatedBy,
                }[header]
            });
            return row;
        });

        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'state actions timeline list');
        XLSX.writeFile(wb, 'state_actions_timeline.xlsx');
        setIsExportModalOpen(false);
    };

    const getSubmissionNumber = (activityId: string) => {
        const activity = reportActivityList.find((element: any) => element.mctReportActivitySid === activityId);
        return activity ? activity.submissionNumber : '';
    };

    const maskUpdatedBy = (value: any) => {
        if (!value) return '';
        if (value.includes('@nebraska.gov')) {
            const visiblePart = value.slice(0, 2);
            const maskedPart = '*'.repeat(value.length - 2);
            return `${visiblePart}${maskedPart}`;
        }
        return value;
    };

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Report Details" />
                </div>
            </div>

            <div className="form-layout">
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                            <Button
                                className="OkButton"
                                disabled={recordData?.last_review_status !== ReviewStatus.ADDITIONAL_INFO_REQUESTED &&
                                    recordData?.last_review_status !== ReviewStatus.REJECTED &&
                                    recordData?.last_review_status !== ReviewStatus.INITIAL_VALIDATION_FAILED &&
                                    recordData?.last_review_status !== "" && recordData?.last_review_status !== ReviewStatus.ACCEPTED &&
                                    recordData?.last_review_status !== null || checkAccess('mco_actions_page', 'NOAC') || checkAccess('mco_actions_page', 'VWNA')

                                }
                                // disabled={recordData?.last_review_status === '20' || recordData?.last_review_status === '35'}
                                onClick={() => handleMcoActionsButton(recordData)}
                            >
                                MCO Actions
                            </Button>

                            <Button
                                className="OkButton"
                                onClick={() => handleReviewButton(recordData)}
                                disabled={isNonStateUser || recordData?.last_review_status === '15' || recordData?.last_review_status === '50' ||
                                    recordData?.last_review_status === "" || recordData?.last_review_status === '40' ||
                                    checkAccess('review_page', 'NOAC') || checkAccess('review_page', 'VWNA')
                                }
                            >
                                Review
                            </Button>

                            <Button
                                className="OkButton"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div className="details-block">
                            <h3><b>Report Information:</b></h3>
                            <div className="row1">
                                <div className="element" >
                                    <InputText
                                        context="registration"
                                        label="MCO Name"
                                        name="plan_name"
                                        placeholder="Health Plan"
                                        value={recordData?.plan_name}
                                        onChange={handleGeneralInputData}
                                        disabled={true}

                                    />
                                </div>
                                <div className="element" >
                                    <InputText
                                        context="registration"
                                        label="Report Name"
                                        name="report_name"
                                        placeholder="Report Name"
                                        value={recordData?.report_name}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1">
                                <div className="element" >
                                    <InputText
                                        context="registration"
                                        label="Reference #"
                                        name="start_reference_number"
                                        placeholder="Reference Number"
                                        value={recordData?.start_reference_number}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" >
                                    <InputText
                                        context="registration"
                                        label="Reporting Type"
                                        name="reporting_type_lkpcd"
                                        placeholder="Reporting Type"
                                        // value={getTextFromShortCode(recordData?.reporting_type_lkpcd || '')}
                                        value={matchingReportingTypeLkpcd(recordData?.reporting_type_lkpcd)}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1">
                                <div className="element" >

                                    <InputText
                                        context="registration"
                                        label=" Submission Channel"
                                        name="report_sbmsn_mode_lkpcd"
                                        placeholder="Channel"
                                        value={matchingSubmissionModelLkpcd(recordData?.report_sbmsn_mode_lkpcd)}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" >
                                    <InputText
                                        context="registration"
                                        label="Frequency"
                                        name="frequency"
                                        placeholder="Frequency"
                                        value={matchingFrequencyLkpcd(recordData?.due_date_frequency_lkpcd)}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1">
                                <div className="element" >
                                    <InputText
                                        label="Period"
                                        name="plan_name"
                                        placeholder="Period"
                                        value={formatDateForInput(recordData?.period_begin_date) + ' - ' + formatDateForInput(recordData?.period_end_date)}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" >
                                    <div className="usa-form-group">
                                        <InputText
                                            label="Original Due Date"
                                            name="original_due_date"
                                            value={formatDateForInput(recordData?.original_due_date)}
                                            onChange={handleGeneralInputData}
                                            placeholder={'MM-DD-YYYY'}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row1">
                                <div className="element" >
                                    <InputText
                                        label="Latest Due Date"
                                        name="latest_due_date"
                                        placeholder={'MM-DD-YYYY'}
                                        value={formatDateForInput(recordData?.latest_due_date)}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" >
                                    <div className="usa-form-group">
                                        <InputText
                                            label="Accepted Due Date"
                                            name="acceptedDueDate"
                                            value={formatDateForInput(recordData?.accepeted_due_date)}
                                            onChange={handleGeneralInputData}
                                            placeholder={'MM-DD-YYYY'}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row1">
                                <div className="element" >
                                    <div className="usa-form-group">
                                        <InputText
                                            context="registration"
                                            label="Submission Status"
                                            name="last_submission_status"
                                            placeholder="Submission Status"
                                            value={matchingBusinessStatusLkpcd(recordData?.last_submission_status)}
                                            onChange={handleGeneralInputData}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="element" >

                                    <InputText
                                        label="Last Submission Date"
                                        name="last_submission_date"
                                        placeholder="Last Submission Date"
                                        value={formatDateAndTime(recordData?.last_submission_date)}
                                        // value={formattedSubmissionDate}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1">
                                <div className="element" >
                                    <div className="usa-form-group">
                                        <InputText
                                            label="Review Status"
                                            name="currentReviewStatus"
                                            // placeholder="Review Status"
                                            value={matchingAfStatusLkpcd(recordData?.last_review_status)}
                                            onChange={handleGeneralInputData}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="element" >
                                    <InputText
                                        context="registration"
                                        label="Review Group"
                                        name="currentgroup"
                                        placeholder="Review Group"
                                        value={
                                            Array.isArray(recordData?.review_group_list)
                                                ? recordData?.review_group_list.map((status: any) => getMatchingMcOrgUnit(status)).join(', ')
                                                : getMatchingMcOrgUnit(recordData?.review_group_list)
                                        }
                                        // value={'MC Control Group'}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1">
                                <div className="element" >
                                    <div className="usa-form-group">
                                        <InputText
                                            context="registration"
                                            label="Submission #"
                                            name="current_submission_number"
                                            placeholder="current Submission"
                                            value={recordData?.current_submission_number}
                                            onChange={handleGeneralInputData}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="element" >
                                    <InputText
                                        context="registration"
                                        label="Actual Submission Channel"
                                        name="actual_sbmsnmode_Lkpcd"
                                        value={matchingSubmissionModelLkpcd(recordData?.actual_sbmsnmode_Lkpcd)}
                                        onChange={handleGeneralInputData}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', marginTop: '20px' }}>
                                <label >
                                    <b>Description:</b>
                                </label>
                                <ReactQuill
                                    style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                                    value={getMatchingMcReportDescription(recordData?.report_name)}
                                    onChange={handleRichTextChangeAppDetails}
                                    className="greyed-out"
                                    readOnly={true}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                            [{ align: [] }], // alignment options
                                            ['clean'], // remove formatting option
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                        <div className="details-block">
                            <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>

                                <Button
                                    className="OkButton"
                                    onClick={toggleModal}
                                    disabled={!reportActivityList || reportActivityList.length === 0 ||
                                        checkAccess('report_detail_page', 'NOAC') || checkAccess('report_detail_page', 'VWNA')}
                                >
                                    Export to Excel
                                </Button>
                            </div>
                            <h3><b>MCO - State Actions Timeline</b></h3>

                            <ReactDataGrid
                                licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                idProperty="key"
                                style={gridStyle}
                                pagination
                                columns={contractReportsHistoryHeaders}
                                dataSource={reportActivityList}
                                sortable={true}
                                // onFilterValueChange={handleFilterChangeGrid}
                                defaultLimit={5}
                                // defaultFilterValue={filterValue}
                                rowHeight={50}
                                onRowClick={isNonStateUser ? undefined : ({ data }) => handleRowClick(data)}
                                className="pointer-cursor-row"
                                enableColumnAutosize={true}

                            />

                            <Modal
                                isOpen={isExportModalOpen}
                                onRequestClose={toggleModal}
                                contentLabel="Export data"
                                style={{
                                    content: {
                                        width: '500px',
                                        height: '500px',
                                        margin: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    },
                                }}
                            >
                                <h2 style={{ marginBottom: '20px' }}><b>Select Fields for Export</b></h2>
                                <div>
                                    <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id="selectAll"
                                            checked={selectedHeaders.length === (headers.length)}
                                            onChange={handleSelectAllChange}
                                            style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                        />
                                        <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                        {(headers).map((header) => (
                                            <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    id={header}
                                                    checked={selectedHeaders.includes(header)}
                                                    onChange={() => handleHeaderChange(header)}
                                                    style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                                />
                                                <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                                    <button
                                        disabled={selectedHeaders.length === 0}
                                        className='usa-button'
                                        onClick={handleExportToExcel}
                                    >
                                        Download Excel
                                    </button>
                                    <button
                                        className='usa-button'
                                        onClick={toggleModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Modal>

                            <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                <label >
                                    <b>Attachments Uploaded/Submitted:</b>
                                </label>
                                <div style={{ marginTop: 10 }}>
                                    <ReactDataGrid
                                        licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                        idProperty="key"
                                        style={gridStyle}
                                        pagination
                                        columns={uploadedAttachmentsHeaders}
                                        dataSource={uploadedFileList}
                                        sortable={true}
                                        // onFilterValueChange={handleFilterChangeGrid}
                                        defaultLimit={5}
                                        // defaultFilterValue={filterValue}
                                        rowHeight={50}
                                        loading={isFilesGridLoading}
                                        enableColumnAutosize={true}
                                    />
                                </div>







                                {/* <div className="element" style={{ marginTop: 10 }}>

                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr style={{ backgroundColor: '#d3d3d3' }}>

                                                    <th style={{ border: '1px solid black' }}>Submission Date</th>
                                                    <th style={{ border: '1px solid black' }}>Submission #</th>
                                                    <th style={{ border: '1px solid black' }}>File Name</th>
                                                    <th style={{ border: '1px solid black' }}>File Type</th>
                                                    <th style={{ border: '1px solid black' }}>MCO Viewable</th>
                                                    <th style={{ border: '1px solid black' }}>Download/View</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ alignContent: 'center' }}>
                                                {uploadedFileList.length > 0 ? (
                                                    uploadedFileList.map((file: any, index: number) => (
                                                        <tr key={index}>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{formatDateAndTime(file.updatedDate)}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                {getSubmissionNumber(file.mctActivityInstanceSid)}
                                                            </td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.fileName}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{matchingUploadedFileTypeLKPCD(file.uploadTypeLkpcd)}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{getMatchingYesOrNoValues(file.mcoViewableFlag)}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                <a href={`${file?.fileLocation}/${file?.blobName}${downloadFileToken()}`} target="_blank" rel="noopener noreferrer" className="file-link" style={{ display: 'inline-flex', margin: '5px' }} title="View File">
                                                                    View File <FaExternalLinkAlt style={{ marginLeft: '5px' }} />
                                                                </a>
                                                                <span style={{ margin: '5px' }}>or</span>
                                                                <a href={`${file?.fileLocation}/${file?.blobName}${downloadFileToken()}`} download={file?.fileName} className="file-link" style={{ display: 'inline-flex', margin: '5px' }} title="Download File">
                                                                    Download <FaDownload style={{ marginLeft: '5px' }} />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={6} style={{ border: '1px solid black', textAlign: 'center' }}>No files available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                <label style={{ marginBottom: '10px' }}>
                                    <b>MCO-State Notes:</b>
                                </label>
                                <ReactQuill
                                    style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                                    value={combinedNotes}
                                    onChange={handleRichTextChangeNotes}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                            [{ align: [] }], // alignment options
                                            ['clean'], // remove formatting option
                                        ],
                                    }}
                                />

                            </div>
                        </div>
                    </>
                }

                <div className="row1">
                    <div className="element" >
                        <p>
                            <b>Last Updated By : </b>
                            {isNonStateUser ? maskUpdatedBy(recordData?.updated_by) : recordData?.updated_by}
                        </p>
                    </div>
                    <div className="element" >
                        <p><b>Last Updated Date : </b>{formatDateAndTime(recordData?.updated_date)}</p>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}