import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _, { set } from 'lodash'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import moment from 'moment'
import Modal from 'react-modal';
import { useLooksUpData } from '../../utils/getLookupTableData';
import './reference-home.css'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { FaDownload } from 'react-icons/fa';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import { downloadFileToken } from "../../../src/utils/url-constant";
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';
import { toast } from 'react-toastify';

export default function ReportCatalogDetails() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [contentTypeList, setContentTypeList] = useState<any>([])
    const [reportGroupList, setReportGroupList] = useState<any>([])
    const [reportFileFormat, setReportFileFormat] = useState<any>([])
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [uploadTypeLKPCDList, setUploadTypeLKPCDList] = useState<any>([])
    const [checklistAfStatusLKPCDList, setChecklistAfStatusLKPCDList] = useState<any>([])
    const [mcoActionLKPCDList, setNcoActionLKPCDList] = useState<any>([])
    const [reviewerGroupLKPCDList, setReviewerGroupLKPCDList] = useState<any>([])
    const userType = sessionStorage.getItem('signedInUserType');
    const { getMasterTableLookupValueData, getMasterTableLookupData, getUserGroupTableLookupData, getOrganizationUnitsTableLookupData } = useLooksUpData();
    const ReportInventoryService = useReportInventoryService();
    let [versionTemplatesList, setVersionTemplatesList] = useState<any>([]);
    let [fileNameTemplatesList, setFileNameTemplatesList] = useState<any>([]);
    let [checklistsList, setChecklistsList] = useState<any>([]);
    let [checkListDetailsList, setChecklistDetailsList] = useState<any>([]);
    let [reviewersGroupsList, setReviewersGroupsList] = useState<any>([]);
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const [checklistItemData, setChecklistItemData] = useState<any>([]);
    const [isCheckListItemModalOpen, setIsECheckListItemModalOpen] = useState(false);
    const { checkAccess } = useAccessControl();
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    const toggleModal = () => {
        setIsECheckListItemModalOpen(false);
    };
    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    const fetchProgramReportData = async (programReportSid: any) => {
        try {
            const response = await ReportInventoryService.getCatalogDetailsLIst(programReportSid);
            if (response) {
                setVersionTemplatesList(response.data.versionTemplates);
                setFileNameTemplatesList(response.data.fileNameTemplates);
                setChecklistsList(response.data.checklists);
                setChecklistDetailsList(response.data.checklistDetails);
                setReviewersGroupsList(response.data.reviewersGroups);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');
                const mcContentType = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_CONTENT_TYPE_LKPCD');
                const mcReportingGroup = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_GROUP_QLFR_LKPCD');
                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcFileFormatLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORT_FILE_TYPE_LKPCD');
                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'BUSINESS_STATUS_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'AF_STATUS_LKPCD');
                const mcFileUploadType = filterAndMapData('MC', 'MC_REPORT_FILENAME_TEMPLATE', 'UPLOAD_TYPE_LKPCD');
                const mcChecklistAfStatusLkpcde = filterAndMapData('RM', 'MC_CHECKLIST', 'CHECKLIST_TYPE_LKPCD');
                const mcMcoActionLkpcde = filterAndMapData('MC', 'MC_REPORT_REVIEWERS_GROUP', 'ACTION_LKPCD');
                const mcReviewerGroupLkpcde = filterAndMapData('NG', 'MC_REPORT_REVIEWERS_GROUP', 'AF_STATUS_LKPCD');


                setReportTypeList(mcReportType);
                setContentTypeList(mcContentType);
                setReportGroupList(mcReportingGroup);
                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setReportFileFormat(mcFileFormatLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setUploadTypeLKPCDList(mcFileUploadType);
                setChecklistAfStatusLKPCDList(mcChecklistAfStatusLkpcde)
                setNcoActionLKPCDList(mcMcoActionLkpcde)
                setReviewerGroupLKPCDList(mcReviewerGroupLkpcde)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    interface CatalogDetailsJson {
        afStatusLkpcd: string;
        associatedFilesCount: string;
        autoValidationFlag: string;
        businessStatusLkpcd: string;
        certificationReqdFlag: string;
        createdBy: string;
        createdDate: string;
        dayOfPeriodLkpcd: string;
        daysNumber: string;
        daysQlfrLkpcd: string;
        delimiterChar: string;
        dueDateFrequencyLkpcd: string;
        dueDatePatternLkpcd: string;
        dueDatePositionLkpcd: string;
        dueOnHolidayFlag: string;
        effectiveDate: string;
        endDate: string;
        exemptionAllowedFlag: string;
        extensionAllowedFlag: string;
        extensionDays: string;
        firstDayOfReportingPeriod: string;
        monthOfPeriodLkpcd: string;
        oprtnlFlag: string;
        piiPhiDataFlag: string;
        predecessorSid: string;
        programCode: string;
        programName: string;
        programReportSid: string;
        reportDescription: string;
        reportFileTypeLkpcd: string;
        reportName: string;
        reportSbmsnModeLkpcd: string;
        reportingContentTypeLkpcd: string;
        reportingGroupQlfrLkpcd: string;
        reportingPeriodQlfrLkpcd: string;
        reportingPeriodValue: string;
        reportingTypeLkpcd: string;
        shiftDirectionLkpcd: string;
        startDate: string;
        stateReferenceNumber: string;
        structuredFileFlag: string;
        updatedBy: string;
        updatedDate: string;
        weekOfPeriodLkpcd: string;
    };

    interface LocationState {
        recordData?: CatalogDetailsJson;
    }

    const recordData = (location?.state as LocationState)?.recordData || null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await fetchGetMasterTableLookupValueData();
                if (recordData?.programReportSid) {
                    await fetchProgramReportData(recordData?.programReportSid);
                }
                await fetchGetMcUserGroupTableLookupValueData();
                await fetchGetMcOrganizationUnitsTableLookupValueData();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    function handleCancel() {
        history.goBack()
    }

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingReportingTypeLkpcd = (data: any) => getMatchingDescription(reportTypeList, data);
    const matchingReportingContentTypeLkpcd = (data: any) => getMatchingDescription(contentTypeList, data);
    const matchingReportingGroupQlfrLkpcd = (data: any) => getMatchingDescription(reportGroupList, data);
    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingReportFileTypeLkpcd = (data: any) => getMatchingDescription(reportFileFormat, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingAfStatusLkpcd = (data: any) => getMatchingDescription(afStatusLKPCDList, data);
    const matchingUploadTypeLkpcd = (data: any) => getMatchingDescription(uploadTypeLKPCDList, data);
    const matchingChecklistAfStatusLkpcd = (data: any) => getMatchingDescription(checklistAfStatusLKPCDList, data);
    const matchingMcoActionLkpcd = (data: any) => getMatchingDescription(mcoActionLKPCDList, data);
    const matchingReviewerGroupLkpcd = (data: any) => getMatchingDescription(reviewerGroupLKPCDList, data);

    const getMatchingMcUserGroup = (data: any) => {
        const matchedItem = mcUserGroupsList.find((code: any) => code.userGroupCode === data);
        return matchedItem?.userGroupName || data;
    };

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchedItem?.organizationUnitName || data;
    };

    const gridStyle = { minHeight: 340, width: '100%' };
    const formatReportVersion = (value: any) => {
        return value.toFixed(1);
    };

    const formatDateForInput = (value: any) => {
        if (value) {
            //     const date = moment.utc(value);
            //     return date.isValid() ? date.format('MM/DD/YYYY') : '';
            return formatDateForDisplay(value, 'MM/DD/YYYY');
        }
        return '';
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            // const date = moment.utc(value);
            // return date.isValid() ? date.format('M/D/YY h:mm A') : '';
            return formatDateForDisplay(value, 'M/D/YY h:mm A');
        }
        return '';

    };

    const getChecklistTypeLkpcd = (key: any) => {
        const detail = checkListDetailsList.find((item: any) => item.checklistId === key);
        return detail ? matchingChecklistAfStatusLkpcd(detail.checklistTypeLkpcd) : key;
    };

    const getChecklistDescription = (key: any) => {
        console.log('checkListDetailsList', checkListDetailsList);
        const detail = checkListDetailsList.find((item: any) => item.checklistId === key);
        return detail ? detail.checklistDescription : key;
    };

    const [viewableText, setViewableText] = useState<any[]>([
        { value: 'Y', description: 'Yes' },
        { value: 'N', description: 'No' },
    ]);
    const getMatchingYesOrNoValues = (data: string): string => {
        const matchedItem = viewableText.find((code) => code.value.toLowerCase() === data.toLowerCase());
        return matchedItem?.description || '';
    };

    const handleChecklistItemClick = async (event: React.MouseEvent<HTMLAnchorElement>, id: string) => {
        event.preventDefault();
        setIsECheckListItemModalOpen(true);
        try {
            // Make the API call based on the ID
            const response = await ReportInventoryService.getCheckListItem(id);
            if (response) {
                setChecklistItemData(response.data);
            }
        } catch (error) {
            console.error('Error making API call:', error);
        }
    };

    const isDisabled = checkAccess('report_master_detail_page', 'VWNA') || checkAccess('report_master_detail_page', 'NOAC');

    const handleDownload = async (recordData: any, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        try {
            console.log('Starting file download...');
            const response = await ReportInventoryService.downloadTemplateFileByFileName('nemcistemplates', recordData?.templateFileName?.toLowerCase());
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = downloadUrl;
            a.download = recordData?.templateFileName || 'template-file.json';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
            toast.success('File download successful.');
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Failed to download error details file. Please try again later.');
        }
    };

    const VersionTemplatesJsonsHeaders: any[] = [
        {
            name: 'reportVersion',
            header: (
                <div className='grid-header-custom-style'>
                    Version
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ value }: { value: number }) => (
                <div>{formatReportVersion(value)}</div>
            ),
        },
        {
            name: 'templateFileName',
            header: (
                <div className='grid-header-custom-style'>
                    Template - Instruction File Name
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
            // maxWidth: 300,
            render: ({ value, data }: { value: string, data: any }) => {
                const fileLocation = data.templateFileLocation;

                return (
                    // fileLocation && fileLocation !== 'NULL' ? (
                    <a
                        className={isDisabled ? 'disabled-row' : "file-link"}
                        onClick={(e) => {
                            if (isDisabled) {
                                e.preventDefault();
                            } else {
                                handleDownload(data, e);
                            }
                        }}
                        href="#"
                        style={{ textDecoration: 'underline', display: 'inline-flex', alignItems: 'center' }}
                    >
                        {value} <FaDownload style={{ marginLeft: '5px', verticalAlign: 'middle' }} />
                    </a>
                    // ) : (
                    //     <span>{value}</span>
                    // )
                );
            }
        },
        {
            name: 'fromDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'toDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
    ]

    const FileNamesJsonsHeaders: any[] = [
        {
            name: 'reportVersion',
            header: (
                <div className='grid-header-custom-style'>
                    Version
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ value }: { value: number }) => (
                <div>{formatReportVersion(value)}</div>
            ),
        },
        {
            name: 'uploadTypeLkpcd',
            header: (
                <div className='grid-header-custom-style'>
                    Upload Type
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{matchingUploadTypeLkpcd(value)}</div>
            ),
        },
        {
            name: 'fileNameTemplate',
            header: (
                <div className='grid-header-custom-style'>
                    File Name Template
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            // maxWidth: 300,
        },
        {
            name: 'allowedXtnsnsLkpcdList',
            header: (
                <div className='grid-header-custom-style'>
                    Extension
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            // maxWidth: 300,
        },
        {
            name: 'fromDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'toDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
    ]

    const ChecklistJsonsHeaders: any[] = [
        {
            name: 'checklistId',
            header: (
                <div className='grid-header-custom-style'>
                    Type
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{getChecklistTypeLkpcd(value)}</div>
            ),
        },
        {
            name: 'checklistId',
            header: (
                <div className='grid-header-custom-style'>
                    Checklist Description
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
            // maxWidth: 300,
            // render: ({ value }: { value: any }) => (
            //     <div>{getChecklistDescription(value)}</div>
            // ),
            render: ({ value, data }: { value: string, data: any }) => (
                <a
                    className={isDisabled ? 'disabled-row' : "file-link"}
                    href="#"
                    onClick={isDisabled ? (e) => e.preventDefault() : (event) => handleChecklistItemClick(event, data.checklistId)}
                    style={{ textDecoration: 'underline', display: 'inline-flex', alignItems: 'center' }}
                >
                    {getChecklistDescription(value)}
                </a>
            ),
        },
        {
            name: 'userGroupCode',
            header: (
                <div className='grid-header-custom-style'>
                    Roles / Reviewers Group
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{getMatchingMcUserGroup(value)}</div>
            ),
        },
        {
            name: 'fromDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'toDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
    ]

    const ReviewGroupJsonsHeaders: any[] = [
        {
            name: 'actionLkpcd',
            header: (
                <div className='grid-header-custom-style'>
                    MCO Actions
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: number }) => (
                <div>{matchingMcoActionLkpcd(value)}</div>
            ),
        },
        {
            name: 'organizationUnitCode',
            header: (
                <div className='grid-header-custom-style'>
                    Reviewers Group
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: number }) => (
                <div>{getMatchingMcOrgUnit(value)}</div>
            ),
        },
        {
            name: 'fromDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'toDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            render: ({ value }: { value: any }) => (
                <div>{formatDateForInput(value)}</div>
            ),
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
    ]

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Report Master Details" />
                </div>
            </div>
            {isLoading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading.....</p>
                </div>
            ) :
                <>
                    <div className="form-layout">
                        <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                            <Button
                                className="OkButton"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div className="details-block">
                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Report Name"
                                        name="reportName"
                                        placeholder="Health Plan"
                                        value={recordData?.reportName}
                                        disabled={true}

                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Program Name"
                                        name="programName"
                                        placeholder="Program Name"
                                        value={recordData?.programName}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Reference #"
                                        name="stateReferenceNumber"
                                        placeholder="Reference Number"
                                        value={recordData?.stateReferenceNumber}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Reporting Type"
                                        name="reportingTypeLkpcd"
                                        placeholder="Reporting Type"
                                        value={matchingReportingTypeLkpcd(recordData?.reportingTypeLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Content Type"
                                        name="reportingContentTypeLkpcd"
                                        placeholder="Reporting Type"
                                        value={matchingReportingContentTypeLkpcd(recordData?.reportingContentTypeLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Reporting Group"
                                        name="reportingGroupQlfrLkpcd"
                                        placeholder="Reporting Type"
                                        value={matchingReportingGroupQlfrLkpcd(recordData?.reportingGroupQlfrLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Frequency"
                                        name="dueDateFrequencyLkpcd"
                                        placeholder="Frequency"
                                        value={matchingFrequencyLkpcd(recordData?.dueDateFrequencyLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Structured Report?"
                                        name="structuredFileFlag"
                                        placeholder="Frequency"
                                        value={getMatchingYesOrNoValues(recordData?.structuredFileFlag ?? '')}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Format"
                                        name="reportFileTypeLkpcd"
                                        placeholder="Frequency"
                                        value={matchingReportFileTypeLkpcd(recordData?.reportFileTypeLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Initial Verification?"
                                        name="autoValidationFlag"
                                        placeholder="autoValidationFlag"
                                        value={getMatchingYesOrNoValues(recordData?.autoValidationFlag ?? '')}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Need Certification"
                                        name="certificationReqdFlag"
                                        placeholder="Frequency"
                                        value={getMatchingYesOrNoValues(recordData?.certificationReqdFlag ?? '')}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Other Associated File?"
                                        name="reportFileTypeLkpcd"
                                        placeholder="Frequency"
                                        value={recordData?.associatedFilesCount}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Extension Allowed Days"
                                        name="extensionDays"
                                        placeholder="extensionDays"
                                        value={recordData?.extensionDays}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="PHI-PII Data"
                                        name="piiPhiDataFlag"
                                        placeholder="PHI-PII Data"
                                        value={getMatchingYesOrNoValues(recordData?.piiPhiDataFlag ?? '')}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>

                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Report Begin Date"
                                        name="startDate"
                                        placeholder="Report Begin Date"
                                        value={formatDateForInput(recordData?.startDate)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Report End Date"
                                        name="endDate"
                                        placeholder="End Date"
                                        value={formatDateForInput(recordData?.endDate)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Effective Date"
                                        name="effectiveDate"
                                        placeholder="Effective Date"
                                        value={formatDateAndTime(recordData?.effectiveDate)}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Business Status"
                                        name="businessStatusLkpcd"
                                        placeholder="Business Status"
                                        value={matchingBusinessStatusLkpcd(recordData?.businessStatusLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label=" Review Status"
                                        name="afStatusLkpcd"
                                        placeholder=" Review Status"
                                        value={matchingAfStatusLkpcd(recordData?.afStatusLkpcd)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputText
                                        context="registration"
                                        label="Updated Datetime"
                                        name="updatedDate"
                                        placeholder="Updated Datetime"
                                        value={formatDateAndTime(recordData?.updatedDate)}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row1" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                        <label style={{ marginBottom: '10px' }}>
                                            <b>Description:</b>
                                        </label>
                                        <ReactQuill
                                            style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                                            value={recordData?.reportDescription}
                                            className="greyed-out"
                                            readOnly={true}
                                            modules={{
                                                toolbar: [
                                                    ['bold', 'italic', 'underline', 'strike'], // formatting options
                                                    [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                                    [{ align: [] }], // alignment options
                                                    ['clean'], // remove formatting option
                                                ],
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="row1">
                            <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                <p><b>Last Updated By : </b>{recordData?.updatedBy}</p>
                            </div>
                            <div className="element" style={{ flex: 1, marginRight: '10px' }}>
                                <p><b>Last Updated Date : </b>{formatDateAndTime(recordData?.updatedDate)}</p>
                            </div>
                        </div> */}

                        <div className="details-block">
                            <h3><b>Version Templates</b></h3>
                            <ReactDataGrid
                                licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                idProperty="key"
                                style={gridStyle}
                                pagination
                                columns={VersionTemplatesJsonsHeaders}
                                dataSource={versionTemplatesList}
                                sortable={true}
                                // onFilterValueChange={handleFilterChangeGrid}
                                defaultLimit={5}
                                // defaultFilterValue={filterValue}
                                rowHeight={50}
                                // onRowClick={({ data }) => handleRowClick(data)}
                                // className="pointer-cursor-row"
                                enableColumnAutosize={true}
                            />
                        </div>

                        <div className="details-block">
                            <h3><b>File Names</b></h3>
                            <ReactDataGrid
                                licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                idProperty="key"
                                style={gridStyle}
                                pagination
                                columns={FileNamesJsonsHeaders}
                                dataSource={fileNameTemplatesList}
                                sortable={true}
                                // onFilterValueChange={handleFilterChangeGrid}
                                defaultLimit={5}
                                // defaultFilterValue={filterValue}
                                rowHeight={50}
                                // onRowClick={({ data }) => handleRowClick(data)}
                                // className="pointer-cursor-row"
                                enableColumnAutosize={true}
                            />
                        </div>

                        <div className="details-block">
                            <h3><b>Associated Checklists</b></h3>
                            <ReactDataGrid
                                licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                idProperty="key"
                                style={gridStyle}
                                pagination
                                columns={ChecklistJsonsHeaders}
                                dataSource={checklistsList}
                                sortable={true}
                                // onFilterValueChange={handleFilterChangeGrid}
                                defaultLimit={5}
                                // defaultFilterValue={filterValue}
                                rowHeight={50}
                                // onRowClick={({ data }) => handleRowClick(data)}
                                // className="pointer-cursor-row"
                                enableColumnAutosize={true}
                            />
                        </div>
                        {loggedInUserDetails.organizationUnitCode === "NEDHHS" ? (
                            <div className="details-block">
                                <h3><b>Reviewer Group Assignments</b></h3>
                                <ReactDataGrid
                                    licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                    idProperty="key"
                                    style={gridStyle}
                                    pagination
                                    columns={ReviewGroupJsonsHeaders}
                                    dataSource={reviewersGroupsList}
                                    sortable={true}
                                    // onFilterValueChange={handleFilterChangeGrid}
                                    defaultLimit={5}
                                    // defaultFilterValue={filterValue}
                                    rowHeight={50}
                                    // onRowClick={({ data }) => handleRowClick(data)}
                                    // className="pointer-cursor-row"
                                    enableColumnAutosize={true}
                                />
                            </div>
                        ) : (
                            null
                        )}
                    </div >
                    <Modal
                        isOpen={isCheckListItemModalOpen}
                        onRequestClose={toggleModal}
                        contentLabel="Checkout list item data"
                        style={{
                            content: {
                                width: '800px',
                                height: '500px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            },
                        }}
                    >
                        <h2 style={{ marginBottom: '20px' }}><b>Submission or Re-Submission Checklist Items</b></h2>
                        <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#d3d3d3' }}>

                                    <th style={{ border: '1px solid black' }}>Item#</th>
                                    <th style={{ border: '1px solid black' }}>Item Description</th>
                                    <th style={{ border: '1px solid black' }}>Mandatory</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checklistItemData.length > 0 ? (
                                    checklistItemData.map((item: any, index: any) => (
                                        <tr key={index}>
                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{item.checklistItemId}</td>
                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{item.itemDescription}</td>
                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{item.optionalFlag}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6} style={{ border: '1px solid black', textAlign: 'center' }}>No checklist items are available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                            <button
                                className='usa-button'
                                onClick={toggleModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                </>
            }

            <Footer />
        </>
    );
}