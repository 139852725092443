import React, { useState, useEffect, useRef, useCallback, act } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _, { set } from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa';
import moment from 'moment-timezone';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import {
    SubmissionStatus, ReviewStatus, ChooseActionsType, ActivityLkpcd, AdhocChecklistId, Organization_Unit_CtgryLkpcd, Reviewer_Type,
    CheckList_Type_Lkpcd, Final_Reviewer_File_Type_Filter
} from '../../utils/statusConstantValues';
import { report } from 'process';
import { downloadFileToken } from "../../../src/utils/url-constant";
import { toast } from 'react-toastify';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Cookies from 'js-cookie'
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';

export default function StateReviewPage() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isFilesGridLoading, setIsFilesGridLoading] = useState(false)
    const location = useLocation();
    const [isRemoveFileModalOpen, setRemoveFileModalOpen] = useState(false);
    const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
    const userType = sessionStorage.getItem('signedInUserType');
    const gridStyle = { minHeight: 340, width: '100%' };
    const [isReviewOptionsModalOpen, setIsReviewOptionsModalOpen] = useState(false);
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [reportActivityLKPCDList, setReportActivityLKPCDList] = useState<any>([])
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [submissionModeList, setSubmissionModeList] = useState<any>([])
    const { getMasterTableLookupValueData, getMasterTableLookupData, getOrganizationUnitsTableLookupData } = useLooksUpData();
    const ReportInventoryService = useReportInventoryService();
    const [latestActivityData, setLatestActivityData] = useState<any>(null)
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const [reviewDecisionLKPCDList, setReviewDecisionLKPCDList] = useState<any>([])
    const [combinedNotes, setCombinedNotes] = useState('');
    const [reportSubActivityList, setReportSubActivityList] = useState<any>([])
    const [reportSubActivityDetails, setReportSubActivityDetails] = useState<any>([])
    const [reportAttachentsubActivityDetails, setReportAttachentsubActivityDetails] = useState<any>([])
    const [subActivityChecklist, setSubActivityChecklist] = useState<any>([])
    const [attachmentActivityDetails, setAttachmentActivityDetails] = useState<any>([])
    const [subActivityNotes, setSubActivityNotes] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [uploadingFileTypeLKPCDList, setUploadingFileTypeLKPCDList] = useState<any>([])
    const [uploadedErrorFileTypeKPCDList, setUploadedErrorFileTypeKPCDList] = useState<any>([])
    const [checklistItems, setChecklistItems] = useState<any>([])
    const [selectedChecklistItemIds, setSelectedChecklistItemIds] = useState<number[]>([]);
    const [uploadedFileList, setUploadedFileList] = useState<any>([])
    const [reviewDecision, setReviewDecision] = useState('');
    const [reviewNumber, setReviewNumber] = useState(0);
    const [submissionNumber, setSubmissionNumber] = useState(0);
    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const reviewerfileInputRef = useRef<HTMLInputElement>(null);
    const [reviewerRemovingFileName, setReviewerRemovingFileName] = useState('');
    const [fileValue, setFileValue] = useState<FileWithMetadata[]>([]);
    const [removingFileName, setRemovingFileName] = useState('');
    const { checkAccess } = useAccessControl();
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";
    let envPrefix = process.env.REACT_APP_ENV_PREFIX || '';

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface ContractReport {
        mct_report_instance_sid: string;
        plan_id: string;
        program_report_sid: string;
        period_begin_date: string;
        period_end_date: string;
        current_due_date: string;
        original_due_date: string;
        current_submission_number: string;
        last_submission_status: string;
        last_submission_date: string;
        last_validation_status: string;
        last_review_status: string;
        latest_due_date: string;
        accepeted_due_date: string;
        notes_summary: string;
        oprtnl_flag: string;
        created_by: string;
        created_date: string;
        updated_by: string;
        updated_date: string;
        plan_name: string;
        report_name: string;
        // review_number: string;
        uploadFiles: string;
        reporting_type_lkpcd: string;
        report_sbmsn_mode_lkpcd: string;
        due_date_frequency_lkpcd: string
    };

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_SUBMSN_STATUS_LKPCD');
                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_REVIEW_STATUS_LKPCD');
                const mcSubmisstionMode = filterAndMapData('AD', 'MC_PROGRAM_REPORT', 'REPORT_SBMSN_MODE_LKPCD');
                const mcReportActivityList = filterAndMapData('NG', 'MCT_REPORT_ACTIVITY', 'ACTIVITY_LKPCD');
                const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');
                const mcUploadingFileTypeList = filterAndMapData('ST', 'MCT_REPORT_ATTACHMENT', 'UPLOAD_TYPE_LKPCD');

                const mcChooseAnActionLKPCD = () => {
                    return data
                        .filter((dt: any) => dt.table_name === "MCT_REPORT_SUBACTIVITY" && dt.column_name === 'DECISION_ACTION_LKPCD')
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                //for error file type
                const mcUploadedErrorFileTypeList = filterAndMapData('VR', 'MCT_REPORT_ATTACHMENT', 'UPLOAD_TYPE_LKPCD');

                setReviewDecisionLKPCDList(mcChooseAnActionLKPCD);
                setUploadingFileTypeLKPCDList(mcUploadingFileTypeList);
                setUploadedErrorFileTypeKPCDList(mcUploadedErrorFileTypeList)
                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setSubmissionModeList(mcSubmisstionMode);
                setReportActivityLKPCDList(mcReportActivityList);
                setReportTypeList(mcReportType);

            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUploadedFilesList = async (instanceId: any, activityId: any) => {
        try {
            setIsLoading(true);
            setIsFilesGridLoading(true);
            let latestReportSubActivityList = reportSubActivityList;

            // Fetch reportSubActivityList if it's empty
            if (reportSubActivityList.length === 0) {
                const response = await ReportInventoryService.getAllSubActivitiesByInstanceId(recordData?.mct_report_instance_sid);
                if (response) {
                    latestReportSubActivityList = response.data;
                    const sortedData = response.data.sort((a: any, b: any) => {
                        const dateA = moment.tz(a.updatedDate, 'America/Chicago');
                        const dateB = moment.tz(b.updatedDate, 'America/Chicago');
                        return dateB.diff(dateA);
                    });
                    setReportSubActivityList(sortedData);
                } else {
                    console.warn('Report Sub Activity List response is missing.');
                }
            }

            const [attachmentsResponse, blobFilesResponse] = await Promise.all([
                ReportInventoryService.getAttachmentByActivityId(instanceId),
                ReportInventoryService.getUploadedFilesListBySid('mcofiles', 'NEMCIS-' + instanceId)
            ]);

            if (attachmentsResponse && blobFilesResponse) {
                const attachments = attachmentsResponse.data;
                const blobFiles = blobFilesResponse.data;

                const filteredAttachments = attachments.filter((attachment: any) => {
                    const matchingActivities = latestReportSubActivityList.filter((activity: any) =>
                        activity.mctReportSubActivitySid === attachment.mctReportSubactivitySid && activity.submitFlag !== 'N'
                    );

                    return matchingActivities.length > 0;
                });

                // Combine the responses based on the id property
                const combinedResponse = filteredAttachments.map((attachment: any) => {
                    const matchingBlobFile = blobFiles.find((blobFile: any) => parseInt(blobFile.attachmentRecordId) === attachment.mctReportAttachmentSid);
                    return {
                        ...attachment,
                        ...matchingBlobFile
                    };
                });

                // Filter the combined response based on the presence of iv files
                const ivFilesPresent = combinedResponse.some((item: any) => item.uploadTypeLkpcd === 'IV');
                const filteredCombinedResponse = ivFilesPresent
                    ? combinedResponse.filter((item: any) => item.uploadTypeLkpcd === 'IV')
                    : combinedResponse;

                setUploadedFileList(filteredCombinedResponse);
            } else {
                console.warn('Attachments or Blob Files response is missing.');
            }
        } catch (e) {
            console.error('Error fetching uploaded files list:', e);
        } finally {
            setIsLoading(false);
            setIsFilesGridLoading(false);
        }
    };

    const fetchOrganizationUnitsTableLookupData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);

            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLatestAvtivityWithoutAttachment = async (id: any) => {
        try {
            const activitysResponse = await ReportInventoryService.getLatestActivityByInstanceId(id);

            if (activitysResponse) {
                const activity = activitysResponse.data;
                setLatestActivityData(activity);
                // if (recordData?.program_report_sid && (activity?.submsnStatusLkpcd === SubmissionStatus.SUBMITTED || activity?.submsnStatusLkpcd === SubmissionStatus.RESUBMITTED)) {
                //     getReportReviewerGroupListByInstanceId(recordData?.program_report_sid, ChooseActionsType.SUBMIT);
                // }
                // getMCTReportSubActivityByActivityId(activity?.mctReportActivitySid);
                fetchUploadedFilesList(activity?.mctReportInstanceSid, activity?.mctReportActivitySid);
                // getAttachmentByActivityId(activity?.mctReportInstanceSid, activity?.mctReportActivitySid);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getCheckListItemsByReportId = async (id: any) => {
        try {
            const isFR = reviewModalData?.organizationUnitCode === Reviewer_Type.FR;
            const adhochChecklistId = isFR ? AdhocChecklistId.CHECKLIST_ID_23 : AdhocChecklistId.CHECKLIST_ID_22;
            const checklistTypeLkpccd = recordData?.program_report_sid
                ? (isFR ? CheckList_Type_Lkpcd.SFRL : CheckList_Type_Lkpcd.SIRL)
                : (isFR ? CheckList_Type_Lkpcd.SFRA : CheckList_Type_Lkpcd.SIRA);

            let response;
            if (!recordData?.program_report_sid) {
                response = await ReportInventoryService.getCheckListItemByCheckListTypeId(adhochChecklistId, checklistTypeLkpccd);
            } else {
                response = await ReportInventoryService.getCheckListItemsByProgramReportSid(id, checklistTypeLkpccd);
            }

            if (response) {
                const { data } = response;
                setChecklistItems(data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAllSubActivitiesByInstanceId = async (id: any) => {
        try {
            setIsLoading(true)
            const response = await ReportInventoryService.getAllSubActivitiesByInstanceId(id);
            if (response) {
                const { data } = response;
                const sortedData = data.sort((a: any, b: any) => {
                    const dateA = moment.tz(a.updatedDate, 'America/Chicago');
                    const dateB = moment.tz(b.updatedDate, 'America/Chicago');
                    return dateB.diff(dateA);
                });
                setReportSubActivityList(sortedData);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false)
        }
    }

    // const getReportReviewerGroupListByInstanceId = async (programReportSid: any, activityLkpcd: any) => {
    //     try {
    //         const reviewerGroupResponse = await ReportInventoryService.getReportReviewerGroupListByInstanceId(programReportSid, activityLkpcd);
    //         if (reviewerGroupResponse) {
    //             const reviewerGroupList = reviewerGroupResponse.data;
    //             setReportSubActivityList(reviewerGroupList);
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    const getMCTReportSubActivityByActivityId = async (activityId: any) => {
        try {
            const reviewerGroupResponse = await ReportInventoryService.getMCTReportSubActivityByActivityId(activityId);
            if (reviewerGroupResponse) {
                const reviewerGroupList = reviewerGroupResponse.data;
                setReportSubActivityList(reviewerGroupList);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getMCTReportSubActivityBySubActivityId = async (subActivityId: any) => {
        try {
            const subActivityResponse = await ReportInventoryService.getMCTReportSubActivityBySubActivityId(subActivityId);
            if (subActivityResponse) {
                const subActivityDetails = subActivityResponse.data;
                setReportSubActivityDetails(subActivityDetails);

            }
        } catch (e) {
            console.error(e);
        }
    }

    const getAttachemntBySubActivityId = async (subActivityId: any) => {
        try {
            const subActivityResponse = await ReportInventoryService.getAttachemntBySubActivityId(subActivityId);
            if (subActivityResponse) {
                const existingFiles = subActivityResponse.data.map((file: any) => ({
                    id: file.mctReportAttachmentSid,
                    fileName: file.fileName,
                    uploadTypeLkpcd: file.uploadTypeLkpcd,
                    uploaded: true,
                    mcoViewable: file.mcoViewableFlag,
                    file: file.fileName,
                }));

                setFileValue(existingFiles);

            }
        } catch (e) {
            console.error(e);
        }
    }

    const getAllChecklistBySubActivityId = async (subActivityId: any) => {
        try {
            const subActivityResponse = await ReportInventoryService.getAllChecklistBySubActivityId(subActivityId);
            if (subActivityResponse) {

                if (subActivityResponse) {
                    const savedChecklistItemIds = subActivityResponse.data.map((item: any) => item.checklistItemId);
                    setSelectedChecklistItemIds(savedChecklistItemIds);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const updatedUploadedFileStatus = async (attachmentRecordId: any) => {
        try {
            const request = {
                attachmentRecordId: attachmentRecordId,
                updatedBy: accounts[0]?.username || '',
                updatedDate: setDateInCST(new Date(), 'YYYY-MM-DDTHH:mm:ss')
            };
            const response = await ReportInventoryService.updatedUploadedFileStatus(request);
            if (response.status === 200) {
                toast.success('File status updated successfully');
            }
        } catch (e) {
            console.error(e);
            toast.error('Error while updating file status');
        }
    }

    const mapApiResponseToLocalState = (apiData: any) => {
        return {
            mct_report_instance_sid: apiData.mctReportInstanceSid,
            plan_id: apiData.planId,
            program_report_sid: apiData.programReportSid,
            period_begin_date: formatDateForDisplay(apiData.periodBeginDate, 'MM/DD/YYYY'),
            period_end_date: formatDateForDisplay(apiData.periodEndDate, 'MM/DD/YYYY'),
            latest_due_date: apiData.latestDueDate,
            current_due_date: apiData.requestedDueDate,
            original_due_date: apiData.originalDueDate,
            accepeted_due_date: apiData.acceptedDueDate,
            current_submission_number: apiData.currentSubmissionNumber,
            last_submission_status: apiData.lastSubmsnStatusLkpcd,
            last_submission_date: apiData.lastSubmsnDate,
            last_validation_status: apiData.lastValidationStatusLkpcd,
            last_review_status: apiData.lastReviewStatusLkpcd,
            notes_summary: apiData.notesSummary,
            oprtnl_flag: apiData.oprtnlFlag,
            created_by: apiData.createdBy,
            created_date: apiData.createdDate,
            updated_by: apiData.updatedBy,
            updated_date: apiData.updatedDate,
            plan_name: (location.state as LocationState)?.recordData?.plan_name || apiData.planId,
            report_name: apiData.reportName,
            // review_number: apiData.stateReferenceNumber,
            uploadFiles: apiData.uploadFiles,
            reporting_type_lkpcd: apiData.reportingTypeLkpcd,
            report_sbmsn_mode_lkpcd: apiData.reportSbmsnModeLkpcd,
            due_date_frequency_lkpcd: apiData.dueDateFrequencyLkpcd,
            actual_sbmsnmode_Lkpcd: apiData.actualSbmsnModeLkpcd,
        };
    };

    const getMctreportinstanceDetailsById = async (id: any) => {
        try {
            const response = await ReportInventoryService.getMctreportinstanceDetailsById(id);
            if (response) {
                const { data } = response;
                const mappedData = mapApiResponseToLocalState(data);
                setRecordData(mappedData);
            }
        } catch (e) {
            console.error(e);
        }
    };

    // const getAttachmentByActivityId = async (mctReportInstanceSid: any, activityId: any) => {
    //     try {
    //         const subActivityResponse = await ReportInventoryService.getAttachmentByActivityId(mctReportInstanceSid, activityId);
    //         if (subActivityResponse) {
    //             const setAttachmentDetails = subActivityResponse.data;
    //             setAttachmentActivityDetails(setAttachmentDetails)

    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    interface LocationState {
        recordData?: ContractReport;
        detailsPageReferenceFalg?: boolean;
    }

    const [recordData, setRecordData] = useState((location?.state as LocationState)?.recordData || null);

    // const recordData = (location?.state as LocationState)?.recordData || null;

    const detailsPageReferenceFalg = (location?.state as LocationState)?.detailsPageReferenceFalg || false;

    const maskUpdatedBy = (value: any) => {
        if (!value) return '';
        const visiblePart = value.slice(0, 2);
        const maskedPart = '*'.repeat(value.length - 2);
        return `${visiblePart}${maskedPart}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchGetMasterTableLookupValueData();
            if (recordData?.mct_report_instance_sid) {
                await getAllSubActivitiesByInstanceId(recordData?.mct_report_instance_sid)
                await fetchLatestAvtivityWithoutAttachment(recordData?.mct_report_instance_sid)
            }
            await fetchOrganizationUnitsTableLookupData();
        };

        setIsLoading(true);
        fetchData()
        setIsLoading(false);

    }, [recordData]);

    useEffect(() => {
        if (reportSubActivityList && reportSubActivityList.length > 0) {
            const notes = reportSubActivityList
                .filter((activity: any) => activity.subActivityNotes && activity.subActivityNotes?.trim() !== '' && activity.submitFlag !== 'N')
                .map((activity: any) => {
                    const formattedDate = formatDateAndTime(activity.updatedDate);
                    return `<p>${formattedDate} - ${activity.organizationUnitCode} -> ${activity?.subActivityNotes?.replace(/<\/?p>/g, '')}</p>`;
                }).join('');
            setCombinedNotes(notes);
        }
    }, [reportSubActivityList]);

    function handleCancel() {
        history.goBack()
    }

    useEffect(() => {
        if (reviewDecision === 'RI') {
            setReviewNumber(latestActivityData?.reviewNumber + 1);
            // setSubmissionNumber(latestActivityData?.submissionNumber + 1);
        } else {
            setReviewNumber(latestActivityData?.reviewNumber);
            // setSubmissionNumber(latestActivityData?.submissionNumber);
        }
    }, [reviewDecision])

    useEffect(() => {
        setReviewNumber(latestActivityData?.reviewNumber);
    }, [latestActivityData])

    const mimeTypeMapping: { [key: string]: string } = {
        'PDF': 'application/pdf',
        'JPEG': 'image/jpeg',
        'PNG': 'image/png',
        'TXT': 'text/plain',
        'XLSX': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'XLS': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    async function handleSaveButton() {
        setLoadingButton(true);
        if (reviewModalData && fileValue.length === 0) {
            try {
                const updatedData = {
                    ...reviewModalData,
                    subActivityNotes: subActivityNotes?.replace(/<\/?[^>]+(>|$)/g, ""),
                    decisionActionLkpcd: reviewDecision,
                    submitFlag: 'N',
                    reviewNumber: reviewNumber,
                    // submissionNumber: submissionNumber,
                    checkListItems: selectedChecklistItemIds,
                    updatedBy: loggedInUserDetails.userEmail || reviewModalData.updatedBy,
                    updatedDate: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss') || reviewModalData.updatedDate,
                };
                await ReportInventoryService.saveStateReviewWithoutFile(updatedData);
                setIsReviewOptionsModalOpen(false);
                clearReviewModalData();
                setTimeout(() => {
                    const fetchData = async () => {
                        if (recordData?.mct_report_instance_sid) {
                            await getAllSubActivitiesByInstanceId(recordData?.mct_report_instance_sid)
                            await getMctreportinstanceDetailsById(recordData?.mct_report_instance_sid)
                            await fetchLatestAvtivityWithoutAttachment(recordData?.mct_report_instance_sid)
                        }
                    };

                    fetchData()
                }, 1000);
            } catch (error) {
                console.error('Error updating record:', error);
                toast.error('An error occurred while saving the revirew. Please try again later.');
            } finally {
                setLoadingButton(false);
            }
        } else if (reviewModalData && fileValue.length !== 0) {
            try {
                setLoadingButton(true);
                const saveFormData = new FormData();
                for (const file of fileValue) {
                    if (typeof file.file !== 'string') {
                        saveFormData.append('files', file.file as File);
                    } else {
                        // Fetch the actual file blob using the blobName
                        try {
                            const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mct_report_instance_sid, file.id);
                            const blob = response.data;
                            const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                            const existingFile = new File([blob], file.fileName, { type: mimeType });
                            saveFormData.append('files', existingFile);
                        } catch (error) {
                            console.error(`Error fetching file blob for ${file.fileName}:`, error);
                            setLoadingButton(false);
                            return;
                        }
                    }
                }
                const updatedSaveData = {
                    ...reviewModalData,
                    subActivityNotes: subActivityNotes?.replace(/<\/?[^>]+(>|$)/g, ""),
                    decisionActionLkpcd: reviewDecision,
                    submitFlag: 'N',
                    uploadTypeLkpcd: fileValue.map(file => file.uploadTypeLkpcd),
                    mcoViewable: fileValue.map(file => file.mcoViewable),
                    checkListItems: selectedChecklistItemIds,
                    submissionModeLkpcd: 'W',
                    reviewNumber: reviewNumber,
                    // submissionNumber: submissionNumber,
                    orgUnitCode: reviewModalData.organizationUnitCode,
                    updatedBy: loggedInUserDetails.userEmail || reviewModalData.updatedBy,
                    updatedDate: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss') || reviewModalData.updatedDate,
                };

                const attachmentBlob = new Blob([JSON.stringify(updatedSaveData)], { type: 'application/json' });
                saveFormData.append('saveStateReviewVo', attachmentBlob);

                ReportInventoryService.saveStateReviewWithFile(saveFormData)
                    .then((response) => {
                        if (response.status === 200) {
                            const validationErrors = response.data.filter(
                                (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                            );

                            if (validationErrors.length > 0) {
                                const hasSpecificError = validationErrors.some((fileError: any) =>
                                    fileError.errors.some((error: any) =>
                                        error.ruleErrorMessage.includes('File name patterns failed')
                                        || error.ruleErrorMessage.includes("Last activity's submit status is not 'Y'")
                                        || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                        || error.ruleErrorMessage.includes("Another user has worked on this report instance already.")
                                    )
                                );

                                if (hasSpecificError) {
                                    const specificError = validationErrors
                                        .flatMap((fileError: any) => fileError.errors)
                                        .find((error: any) =>
                                            error.ruleErrorMessage.includes('File name patterns failed')
                                            || error.ruleErrorMessage.includes("Last activity's submit status is not 'Y'")
                                            || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                            || error.ruleErrorMessage.includes("Another user has worked on this report instance already.")
                                        );

                                    if (specificError) {
                                        const fileError = validationErrors.find((fileError: any) =>
                                            fileError.errors.some((error: any) => error.ruleErrorMessage === specificError.ruleErrorMessage)
                                        );

                                        if (fileError && specificError.ruleErrorMessage.includes('File name patterns failed')) {
                                            toast.error(`File name with ${fileError.fileName} is not matching with required filename templates. Please correct the name and upload the file.`);
                                        } else {
                                            toast.error(specificError.ruleErrorMessage || "An error occurred.");
                                        }
                                    }
                                }
                            } else {
                                toast.success('Review record saved successfully.');
                                setIsReviewOptionsModalOpen(false);
                                clearReviewModalData();
                                setTimeout(() => {
                                    const fetchData = async () => {
                                        if (recordData?.mct_report_instance_sid) {
                                            await getAllSubActivitiesByInstanceId(recordData?.mct_report_instance_sid)
                                            await getMctreportinstanceDetailsById(recordData?.mct_report_instance_sid)
                                            await fetchLatestAvtivityWithoutAttachment(recordData?.mct_report_instance_sid)
                                        }
                                    };

                                    fetchData()
                                }, 1000);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                        toast.error('An error occurred while saving the record. Please try again later.');
                    })
                    .finally(() => {
                        setLoadingButton(false);
                    });
            } catch (error) {
                console.error('Error updating record:', error);
                setLoadingButton(false);
                toast.error('An error occurred while saving the record. Please try again later.');
            }
            finally {
                setLoadingButton(false);
            }
        }
    }

    async function handleSubmitButton() {
        setLoadingButton(true);
        if (reviewModalData && fileValue.length === 0) {
            try {
                const updatedData = {
                    ...reviewModalData,
                    subActivityNotes: subActivityNotes?.replace(/<\/?[^>]+(>|$)/g, ""),
                    decisionActionLkpcd: reviewDecision,
                    submitFlag: 'Y',
                    reviewNumber: reviewNumber,
                    // submissionNumber: submissionNumber,
                    checkListItems: selectedChecklistItemIds,
                    updatedBy: loggedInUserDetails.userEmail || reviewModalData.updatedBy,
                    updatedDate: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss') || reviewModalData.updatedDate,
                    activityEndDateTime: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
                };
                await ReportInventoryService.submitStateReviewWithoutFile(updatedData)
                    .then((response) => {
                        if (response.status === 200) {
                            setIsReviewOptionsModalOpen(false);
                            clearReviewModalData();
                            setTimeout(() => {
                                const fetchData = async () => {
                                    if (recordData?.mct_report_instance_sid) {
                                        await getAllSubActivitiesByInstanceId(recordData?.mct_report_instance_sid)
                                        await getMctreportinstanceDetailsById(recordData?.mct_report_instance_sid)
                                        await fetchLatestAvtivityWithoutAttachment(recordData?.mct_report_instance_sid)
                                    }
                                };

                                fetchData()
                            }, 1000);
                            toast.success('Review record submitted successfully.');
                        }
                        else {
                            toast.error('An error occurred while submitting the revirew. Please try again later.');
                        }
                    })

            } catch (error) {
                console.error('Error updating record:', error);
                toast.error('An error occurred while submitting the revirew. Please try again later.');
            }
            finally {
                setLoadingButton(false);
            }
        } else if (reviewModalData && fileValue.length !== 0) {
            try {
                const submitFormData = new FormData();
                for (const file of fileValue) {
                    if (typeof file.file !== 'string') {
                        submitFormData.append('files', file.file as File);
                    } else {
                        // Fetch the actual file blob using the blobName
                        try {
                            const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mct_report_instance_sid, file.id);
                            const blob = response.data;
                            const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                            const existingFile = new File([blob], file.fileName, { type: mimeType });
                            submitFormData.append('files', existingFile);
                        } catch (error) {
                            console.error(`Error fetching file blob for ${file.fileName}:`, error);
                            setLoadingButton(false);
                            return;
                        }
                    }
                }
                const updatedSubmitData = {
                    ...reviewModalData,
                    subActivityNotes: subActivityNotes?.replace(/<\/?[^>]+(>|$)/g, ""),
                    decisionActionLkpcd: reviewDecision,
                    submitFlag: 'Y',
                    reviewNumber: reviewNumber,
                    // submissionNumber: submissionNumber,
                    uploadTypeLkpcd: fileValue.map(file => file.uploadTypeLkpcd),
                    mcoViewable: fileValue.map(file => file.mcoViewable),
                    checkListItems: selectedChecklistItemIds,
                    submissionModeLkpcd: 'W',
                    orgUnitCode: reviewModalData.organizationUnitCode,
                    updatedBy: loggedInUserDetails.userEmail || reviewModalData.updatedBy,
                    updatedDate: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss') || reviewModalData.updatedDate,
                    activityEndDateTime: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
                };

                const attachmentBlob = new Blob([JSON.stringify(updatedSubmitData)], { type: 'application/json' });
                submitFormData.append('submitStateReviewVo', attachmentBlob);

                ReportInventoryService.submitStateReviewWithFile(submitFormData)
                    .then((response) => {
                        if (response.status === 200) {
                            const validationErrors = response.data.filter(
                                (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                            );

                            if (validationErrors.length > 0) {
                                const hasSpecificError = validationErrors.some((fileError: any) =>
                                    fileError.errors.some((error: any) =>
                                        error.ruleErrorMessage.includes('File name patterns failed')
                                        || error.ruleErrorMessage.includes("Last activity's submit status is not 'Y'")
                                        || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                        || error.ruleErrorMessage.includes("Another user has worked on this report instance already.")
                                    )
                                );

                                if (hasSpecificError) {
                                    const specificError = validationErrors
                                        .flatMap((fileError: any) => fileError.errors)
                                        .find((error: any) =>
                                            error.ruleErrorMessage.includes('File name patterns failed')
                                            || error.ruleErrorMessage.includes("Last activity's submit status is not 'Y'")
                                            || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                            || error.ruleErrorMessage.includes("Another user has worked on this report instance already.")
                                        );

                                    if (specificError) {
                                        const fileError = validationErrors.find((fileError: any) =>
                                            fileError.errors.some((error: any) => error.ruleErrorMessage === specificError.ruleErrorMessage)
                                        );

                                        if (fileError && specificError.ruleErrorMessage.includes('File name patterns failed')) {
                                            toast.error(`File name with ${fileError.fileName} is not matching with required filename templates. Please correct the name and upload the file.`);
                                        } else {
                                            toast.error(specificError.ruleErrorMessage || "An error occurred.");
                                        }
                                    }
                                }
                            } else {
                                toast.success('Review record submitted successfully.');
                                setIsReviewOptionsModalOpen(false);
                                clearReviewModalData();
                                setTimeout(() => {
                                    const fetchData = async () => {
                                        if (recordData?.mct_report_instance_sid) {
                                            await getAllSubActivitiesByInstanceId(recordData?.mct_report_instance_sid)
                                            await getMctreportinstanceDetailsById(recordData?.mct_report_instance_sid)
                                            await fetchLatestAvtivityWithoutAttachment(recordData?.mct_report_instance_sid)
                                        }
                                    };

                                    fetchData()
                                }, 1000);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                        toast.error('An error occurred while submitting the record. Please try again later.');
                    })
                    .finally(() => {
                        setLoadingButton(false);
                    });
            } catch (error) {
                console.error('Error updating record:', error);
                setLoadingButton(false);
                toast.error('An error occurred while submitting the record. Please try again later.');
            }
            finally {
                setLoadingButton(false);
            }
        }
    }

    interface FileWithMetadata {
        id: number;
        fileName: string;
        uploadTypeLkpcd: string;
        mcoViewable: string;
        uploaded: boolean;
        file: File | String;
        blobName?: string;
    }

    // useEffect(() => {
    //     if (recordData) {
    //         const uploadedFiles = Array.isArray(recordData.uploadFiles) ? recordData.uploadFiles : [];
    //         setFileValue(uploadedFiles);
    //     }
    // }, [recordData]);

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingAfStatusLkpcd = (data: any) => getMatchingDescription(afStatusLKPCDList, data);
    const matchingSubmissionModelLkpcd = (data: any) => getMatchingDescription(submissionModeList, data);
    const matchingreportActivityLKPCD = (data: any) => getMatchingDescription(reportActivityLKPCDList, data);
    const matchingReportingTypeLkpcd = (data: any) => getMatchingDescription(reportTypeList, data);
    const matchingUploadedFileTypeLKPCD = (data: any) => getMatchingDescription(uploadingFileTypeLKPCDList, data);
    const matchingUploadedErrorFileTypeLKPCD = (data: any) => getMatchingDescription(uploadedErrorFileTypeKPCDList, data);

    const [viewableText, setViewableText] = useState<any[]>([
        { value: 'Y', description: 'Yes' },
        { value: 'N', description: 'No' },
    ]);
    const getMatchingYesOrNoValues = (data: string): string => {
        const matchedItem = viewableText.find((code) => code.value.toLowerCase() === data.toLowerCase());
        return matchedItem?.description || '';
    };

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchedItem?.organizationUnitName || data;
    };

    const getMatchingDecisionStatus = (data: any) => {
        const matchedItem = reviewDecisionLKPCDList.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const handleRichTextChangeCobmineNotes = (value: any) => {
        // setSubActivityNotes(value)
    }

    const handleRichTextChangeNotes = useCallback((value: string) => {
        setSubActivityNotes(value);
    }, []);


    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const formatDateForInput = (value: any) => {
        if (value) {
            const date = moment.utc(value);
            return date.isValid() ? date.format('MM/DD/YYYY') : '';
            // return formatDateForDisplay(value, 'YYYY-MM-DD');
        }
        return '';
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            //    return formatDateForDisplay(value, 'MM/DD/YYYY h:mm:ss:SSS A');

            const date = moment.utc(value);
            return date.isValid() ? date.format('MM/DD/YYYY h:mm:ss:SSS A') : '';
        }
        return '';
    };

    const [reviewModalData, setReviewModalData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (reviewModalData) {
                setReviewDecision(reviewModalData.decisionActionLkpcd);
                setSubActivityNotes(reviewModalData.subActivityNotes);
                await getAttachemntBySubActivityId(reviewModalData.mctReportSubActivitySid);
                await getAllChecklistBySubActivityId(reviewModalData.mctReportSubActivitySid);
                await getCheckListItemsByReportId(recordData?.program_report_sid);
            }
        };
        fetchData();
    }, [reviewModalData]);

    const getOrganizationUnitCtgryLkpcd = () => {
        const orgUnit = mcOrgUnitsList.find((unit: any) => unit.organizationUnitCode === reviewModalData?.organizationUnitCode);
        return orgUnit ? orgUnit.organizationUnitCtgryLkpcd : Organization_Unit_CtgryLkpcd.NMR;
    };

    const organizationUnitCtgryLkpcd = getOrganizationUnitCtgryLkpcd();
    const reviewerTypeValue = organizationUnitCtgryLkpcd === Organization_Unit_CtgryLkpcd.NMR ? Reviewer_Type.IR : Reviewer_Type.FR;

    const filterAndMapAsDropdownData = reviewDecisionLKPCDList
        .filter((code: any) => code.lkpcd_group === reviewerTypeValue)
        .map((code: any) => {
            return {
                value: `${code.lkpdmn_lkpcd}`,
                label: code.lkdmn_lkpcd_desc,
                lkpcd_group: code.lkpcd_group,
            };
        });

    const getSubmissionNumber = (activityId: string) => {
        const activity = reportSubActivityList.find((element: any) => element.mctReportActivitySid === activityId);
        return activity ? activity.submissionNumber : '';
    };


    const handleReviewDecisionDropdownData = (event: any) => {
        const { name, value } = event.target
        setReviewDecision(value)
    }

    const openRemoveFileModal = (fileId: any) => {
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        setRemovingFileName(fileValue.find(f => f.id === fileId)?.fileName || '');
    };

    const closeRemoveFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setRemovingFileName('')
    };

    const openRemoveReviewerFileModal = (fileId: any) => {
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        setReviewerRemovingFileName(fileValue.find(f => f.id === fileId)?.fileName || '');
    };

    const closeRemoveReviewerFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setReviewerRemovingFileName('')
    };

    const confirmReviewerDelete = () => {
        if (fileIdToDelete !== null) {
            uploadedFileList.forEach((file: any) => {
                if (file.attachmentRecordId === fileIdToDelete) {
                    updatedUploadedFileStatus(fileIdToDelete)
                }
            })

            handleReviewerRemoveFile(fileIdToDelete);

        } else {
            if (fileIdToDelete !== null) {
                handleReviewerRemoveFile(fileIdToDelete);
            }
        }

        closeRemoveFileModal();
    };

    interface ReviewerActions {
        organizationUnitCode: string;
        decisionStatus: string;
        updatedDate: string;
        lastUpdatedBy: string;
    };

    const hasFRRecord = reportSubActivityList.some((record: any) => record.organizationUnitCode === 'FR');

    const rowClassName = ({ data }: { data: any }) => {
        if (hasFRRecord) {
            return data.submitFlag === 'Y' ? 'disabled-row' : '';
        }
        return '';
    };

    const reviewerActionsHeaders: any[] = [
        {
            name: 'organizationUnitCode',
            header: (
                <div className='grid-header-custom-style'>
                    Reviewer Group
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            render: ({ value }: { value: any }) => {
                const value1 = getMatchingMcOrgUnit(value);
                return (
                    <div title={value1}>
                        {value1}
                    </div>
                );
            },
        },
        {
            name: 'decisionActionLkpcd',
            header: (
                <div className='grid-header-custom-style'>
                    Decision
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            render: ({ value }: { value: any }) => (
                <div>{getMatchingDecisionStatus(value)}</div>
            ),
        },
        {
            name: 'submitFlag',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Activity Status
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ value }: any) => {
                switch (value) {
                    case 'Y':
                        return 'Completed';
                    case 'N':
                        return 'Pending';
                    default:
                        return '';
                }
            }
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ value }: { value: any }) => (
                <div>{formatDateAndTime(value)}</div>
            ),
        },
        {
            name: 'updatedBy',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Last Updated By
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'action',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Action
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 130,
            render: ({ data }: { data: any }) => {
                const handleColumnClick = () => {
                    setReviewModalData(data)
                    setIsReviewOptionsModalOpen(true)
                };

                return (
                    <Button className='okButton'
                        disabled={!checkAccess('review_page', 'FULL')}
                        onClick={handleColumnClick}
                    >
                        Update
                    </Button>
                );
            },
        }
    ]

    const handleDownload = async (recordData: any, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        try {
            console.log('Starting file download...');
            const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + recordData?.mctReportInstanceSid, recordData?.attachmentRecordId);
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = downloadUrl;
            a.download = recordData?.fileName || 'error-details.json';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
            toast.success('File download successful.');
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Failed to download error details file. Please try again later.');
        }
    };

    const isDisabled = checkAccess('review_page', 'VWNA') || checkAccess('review_page', 'NOAC');

    const uploadedAttachmentsHeaders: any[] = [
        {
            name: 'updatedDate',
            header: (
                <div className='grid-header-custom-style'>
                    Submission Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            // maxWidth: 200,
            render: ({ data }: { data: any }) => {
                return formatDateAndTime(data.updatedDate);
            },
        },
        {
            name: 'mctActivityInstanceSid',
            header: (
                <div className='grid-header-custom-style'>
                    Submission #
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 90,
            maxWidth: 150,
            render: ({ data }: { data: any }) => {
                return getSubmissionNumber(data.mctActivityInstanceSid);
            },

        },
        {
            name: 'fileName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        File Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 300,
            // maxWidth: 350,
        },
        {
            name: 'uploadTypeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        File Type
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            // maxWidth: 150,
            render: ({ data }: { data: any }) => {
                if (data?.uploadTypeLkpcd === 'IV') {
                    return matchingUploadedErrorFileTypeLKPCD(data.uploadTypeLkpcd)
                } else {
                    return matchingUploadedFileTypeLKPCD(data.uploadTypeLkpcd)
                }
            },
        },
        {
            name: 'mcoViewableFlag',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO Viewable
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 120,
            // maxWidth: 200,
            render: ({ data }: { data: any }) => {
                return getMatchingYesOrNoValues(data.mcoViewableFlag)
            },
        },
        {
            name: 'action',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Download/View
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            // maxWidth: 200,
            render: ({ data }: { data: any }) => {

                const isJsonFile = data?.fileName?.endsWith('_error.json');

                return (
                    <div>
                        <a className={isDisabled ? 'disabled-row' : "file-link"}
                            onClick={isDisabled ? (e) => e.preventDefault() : (e) => handleDownload(data, e)}
                            href={isDisabled ? undefined : '#'}
                            target="_blank" rel="noopener noreferrer"
                            style={{ display: 'inline-flex', margin: '5px' }}
                            title="View File">
                            View File <FaExternalLinkAlt style={{ marginLeft: '5px' }} />
                        </a>
                        <span style={{ margin: '5px' }}>or</span>

                        {isJsonFile ? (
                            <a
                                className={isDisabled ? 'disabled-row' : 'file-link'}
                                onClick={isDisabled ? (e) => e.preventDefault() : (e) => handleDownload(data, e)}
                                href={isDisabled ? undefined : '#'}
                                download={isDisabled ? undefined : data?.fileName}
                                style={{ display: 'inline-flex', margin: '5px' }}
                                title="Download File"
                            >
                                Download <FaDownload style={{ marginLeft: '5px' }} />
                            </a>
                        ) : (
                            <a
                                className={isDisabled ? 'disabled-row' : 'file-link'}
                                onClick={isDisabled ? (e) => e.preventDefault() : (e) => handleDownload(data, e)}
                                href={isDisabled ? undefined : '#'}
                                download={isDisabled ? undefined : data?.fileName}
                                style={{ display: 'inline-flex', margin: '5px' }}
                                title="Download File"
                            >
                                Download <FaDownload style={{ marginLeft: '5px' }} />
                            </a>
                        )}
                    </div>
                );
            },
        }
    ]

    const clearReviewModalData = () => {
        setReviewDecision('')
        setSubActivityNotes('')
        setFileValue([])
        setSelectedChecklistItemIds([])
        setChecklistItems([])
    };

    const closeReviewModal = () => {
        setIsReviewOptionsModalOpen(false);
        // setReviewDecision('')
        // setSubActivityNotes('')
        // setFileValue([])
        // setSelectedChecklistItemIds([])
        // setChecklistItems([])
    };

    const handleReviewerRemoveFile = (fileId: string) => {
        setFileValue(prevFiles => prevFiles.filter(f => f.id !== Number(fileId)));
    };

    const handleReviewerFileUploadButtonClick = () => {
        if (reviewerfileInputRef.current) {
            reviewerfileInputRef.current.click();
        }
    };

    const handleReviewerFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map(file => ({
                id: Date.now(),
                fileName: file.name,
                uploadTypeLkpcd: '',
                mcoViewable: '',
                uploaded: true,
                file: file
            }));
            setFileValue(prevFiles => {
                // Merge new files with existing files, avoiding duplicates
                const existingFiles = prevFiles.filter(file => !newFiles.some(newFile => newFile.fileName === file.fileName));
                return [...existingFiles, ...newFiles];
            });
        }
    };

    const handleFileTypeChange = (e: any, fileId: any) => {
        const updatedFiles = fileValue.map((file) =>
            file.id === fileId ? { ...file, uploadTypeLkpcd: e.target.value || file.uploadTypeLkpcd } : file
        );
        setFileValue(updatedFiles);
    };

    const handleReviewerMcoViewableChange = (e: any, fileId: any) => {
        const updatedFiles = fileValue.map((file) =>
            file.id === fileId ? { ...file, mcoViewable: e.target.value || file.mcoViewable } : file
        );
        setFileValue(updatedFiles);
    };

    const handleChecklistItemChange = (checklistItemId: number) => {
        setSelectedChecklistItemIds(prevSelected => {
            if (prevSelected.includes(checklistItemId)) {
                // Remove the checklistItemId if it is already selected
                return prevSelected.filter(id => id !== checklistItemId);
            } else {
                // Add the checklistItemId if it is not selected
                return [...prevSelected, checklistItemId];
            }
        });
    };

    useEffect(() => {
        // setSelectedChecklistItemIds(selectedChecklistItemIds)
    }, [selectedChecklistItemIds]);

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="State Review" />
                </div>
            </div>

            <div className="form-layout">
                <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                    <Button
                        className="OkButton"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="details-block">
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="MCO Name"
                                name="plan_name"
                                value={recordData?.plan_name}
                                onChange={handleGeneralInputData}
                                disabled={true}

                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Report Name"
                                name="report_name"
                                value={recordData?.report_name}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Reporting Type"
                                name="reporting_type_lkpcd"
                                value={matchingReportingTypeLkpcd(recordData?.reporting_type_lkpcd)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Submission Channel"
                                name="report_sbmsn_mode_lkpcd"
                                value={matchingSubmissionModelLkpcd(recordData?.report_sbmsn_mode_lkpcd)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                label="Period"
                                name="period"
                                value={formatDateForInput(recordData?.period_begin_date) + ' - ' + formatDateForInput(recordData?.period_end_date)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    label="Original Due Date"
                                    name="latest_due_date"
                                    value={formatDateForInput(recordData?.original_due_date)}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                label="Latest Due Date"
                                name="latest_due_date"
                                placeholder={'MM-DD-YYYY'}
                                value={formatDateForInput(recordData?.latest_due_date)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    label="Accepted Due Date"
                                    name="acceptedDueDate"
                                    value={formatDateForInput(recordData?.accepeted_due_date)}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                // choices={submissionStatusDropdown}
                                // emptyChoiceLabel="Select submission status"
                                name="last_submission_status"
                                label="Current Submission Status"
                                data-testid="payment_type_testid"
                                value={matchingBusinessStatusLkpcd(recordData?.last_submission_status)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    label="Last Submission Date"
                                    name="last_submission_date"
                                    value={formatDateAndTime(recordData?.last_submission_date)}
                                    onChange={handleGeneralInputData}
                                    // placeholder={'MM-DD-YYYY'}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                // choices={submissionReviewDropdown}
                                // emptyChoiceLabel="Select submission status"
                                name="last_review_status"
                                label="Current Review Status"
                                data-testid="payment_type_testid"
                                value={matchingAfStatusLkpcd(recordData?.last_review_status)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                label="Last Updated Date"
                                name="updated_date"
                                value={formatDateAndTime(recordData?.updated_date)}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                label="Submission#"
                                name="current_submission_number"
                                value={recordData?.current_submission_number}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    label="Review#"
                                    name="reviewNumber"
                                    value={reviewNumber}
                                    onChange={handleGeneralInputData}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <div className="details-block">
                            <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                <label >
                                    <b>Reviewer's Actions:</b>
                                </label>
                                <ReactDataGrid
                                    licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                    idProperty="key"
                                    style={gridStyle}
                                    pagination
                                    columns={reviewerActionsHeaders}
                                    dataSource={reportSubActivityList}
                                    sortable={true}
                                    rowClassName={rowClassName}
                                    defaultLimit={5}
                                    rowHeight={50}
                                    enableColumnAutosize={true}

                                />
                            </div>
                            {/* {Reviewer Internal Notes} */}
                            <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                <label >
                                    <b>Reviewer's Internal Notes Timeline:</b>
                                </label>
                                <ReactQuill
                                    style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                                    value={combinedNotes}
                                    onChange={handleRichTextChangeCobmineNotes}
                                    readOnly={true}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                            [{ align: [] }], // alignment options
                                            ['clean'], // remove formatting option
                                        ],
                                    }}
                                />
                            </div>

                            {!detailsPageReferenceFalg && (
                                <>
                                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                        <label style={{ marginBottom: '10px' }}>
                                            <b>Reviewer Attachments, if any required:</b>
                                        </label>
                                        <div style={{ marginTop: 10 }}>
                                            <ReactDataGrid
                                                licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                                                idProperty="key"
                                                style={gridStyle}
                                                pagination
                                                columns={uploadedAttachmentsHeaders}
                                                dataSource={uploadedFileList}
                                                sortable={true}
                                                // onFilterValueChange={handleFilterChangeGrid}
                                                defaultLimit={5}
                                                // defaultFilterValue={filterValue}
                                                rowHeight={50}
                                                loading={isFilesGridLoading}
                                                enableColumnAutosize={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                }

                {/* Reviewer Options Modal */}
                <Modal
                    isOpen={isReviewOptionsModalOpen}
                    onRequestClose={closeReviewModal}
                    contentLabel="Reviewr Options"
                    className="OverlayMainContainer"
                    overlayClassName="OverlayContainer"

                >
                    <div style={{ fontSize: '20px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <h2><b>{getMatchingMcOrgUnit(reviewModalData?.organizationUnitCode)} - Review</b></h2>
                    </div>

                    {/* {Decision} */}
                    <div className="element" >
                        <Dropdown
                            choices={filterAndMapAsDropdownData}
                            emptyChoiceLabel="Select action"
                            name="reviewDecision"
                            label="Decision"
                            data-testid="payment_type_testid"
                            value={reviewDecision}
                            onChange={handleReviewDecisionDropdownData}
                            mandatoryLabel={true}
                            disabled={detailsPageReferenceFalg ? true : false}
                        />
                    </div>

                    {/* {Notes} */}
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            {reviewModalData?.organizationUnitCode === 'FR' ? <b>Notes:<span className="usa-mandatory-label">*</span></b> : <b>Notes:</b>
                            }
                            {/* <b>Notes:<span className="usa-mandatory-label">
                                *
                            </span></b> */}
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value={subActivityNotes}
                            className={detailsPageReferenceFalg ? "greyed-out" : ''}
                            readOnly={detailsPageReferenceFalg ? true : false}
                            onChange={handleRichTextChangeNotes}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    [{ align: [] }],
                                    ['clean'],
                                ],
                            }}
                        />
                        <p style={{ fontSize: '10px' }}>Only Final Reviewer's notes will be viewable to MCO</p>
                    </div>

                    {/*Upload files */}
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Reviewer Attachments, if any required:</b>
                        </label>
                        <div className="element">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>
                                            <th style={{ border: '1px solid black' }}>No</th>
                                            <th style={{ border: '1px solid black' }}>File Name</th>
                                            <th style={{ border: '1px solid black' }}>File Type</th>
                                            <th style={{ border: '1px solid black' }}>MCO Viewable</th>
                                            <th style={{ border: '1px solid black' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        <tr>
                                            <td colSpan={4} style={{ border: '1px solid black', textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5px', marginTop: '-5px' }}>
                                                    <Button
                                                        className="choose-file-button"
                                                        disabled={!checkAccess('review_page', 'FULL')}
                                                        onClick={handleReviewerFileUploadButtonClick}
                                                    >
                                                        Add an Attachment
                                                    </Button>
                                                    <input
                                                        type="file"
                                                        ref={reviewerfileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleReviewerFileChange}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        {fileValue.length > 0 ? (
                                            fileValue.map((file, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{index + 1}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.fileName}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                        {file.uploaded ? (
                                                            <div>
                                                                <select
                                                                    value={file.uploadTypeLkpcd || ""}
                                                                    onChange={(e) => handleFileTypeChange(e, file.id)}
                                                                >
                                                                    <option value="">Select File Type</option>
                                                                    {uploadingFileTypeLKPCDList
                                                                        .filter((item: any) => {
                                                                            if (reviewDecision === Final_Reviewer_File_Type_Filter.REQUEST_ADD_INFO && reviewModalData?.organizationUnitCode === Reviewer_Type.FR) {
                                                                                return item.lkpdmn_lkpcd === Final_Reviewer_File_Type_Filter.OTHER;
                                                                            }
                                                                            return true;
                                                                        })
                                                                        .map((item: any) => (
                                                                            <option key={item.lkpdmn_lkpcd} value={item.lkpdmn_lkpcd}>
                                                                                {item.lkdmn_lkpcd_desc}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                <span className="usa-mandatory-label" style={{ marginLeft: '5px', color: 'red' }}>*</span>
                                                            </div>
                                                        ) : (
                                                            matchingUploadedFileTypeLKPCD(file.uploadTypeLkpcd)
                                                        )}
                                                    </td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                        {file.uploaded ? (
                                                            <div>
                                                                <select
                                                                    value={file.mcoViewable || ""}
                                                                    onChange={(e) => handleReviewerMcoViewableChange(e, file.id)}
                                                                >
                                                                    <option value="">Select MCO Viewable</option>
                                                                    <option value="Y">Yes</option>
                                                                    <option value="N">No</option>

                                                                </select>
                                                                <span className="usa-mandatory-label" style={{ marginLeft: '5px', color: 'red' }}>*</span>
                                                            </div>
                                                        ) : (
                                                            file.mcoViewable
                                                        )}
                                                    </td>
                                                    <td style={{ border: '1px solid black' }}>
                                                        {file.fileName && (
                                                            <button
                                                                type="button"
                                                                onClick={() => openRemoveReviewerFileModal(file.id.toString())}
                                                                style={{ border: '1px solid red', borderRadius: '5px', padding: '5px', fontSize: '14px' }}
                                                            >
                                                                Delete
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5} style={{ border: '1px solid black', textAlign: 'center', fontSize: '14px' }}>No files available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={isRemoveFileModalOpen}
                        onRequestClose={closeRemoveFileModal}
                        contentLabel="Confirm Remove"
                        className="OverlayMainContainer"
                        overlayClassName="OverlayContainer"
                        style={{
                            content: {
                                width: '500px',
                                height: '200px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                border: '1px solid red',
                            },
                        }}
                    >
                        <h2><b style={{ fontSize: '20px' }}>Confirm Remove</b></h2>
                        <p style={{ marginTop: '10px', fontSize: '18px', }}>Are you sure you want to delete this file?</p>
                        <p style={{ marginTop: '10px' }}>{reviewerRemovingFileName}</p>
                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                            <button
                                className='usa-button'
                                onClick={confirmReviewerDelete}
                            >
                                Yes
                            </button>
                            <button
                                className='usa-button'
                                onClick={closeRemoveReviewerFileModal}
                            >
                                No
                            </button>
                        </div>
                    </Modal>

                    {/* {Checklist} */}
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Checklist:</b>
                        </label>

                        <div className="element">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>
                                            <th style={{ border: '1px solid black' }}>Select</th>
                                            <th style={{ border: '1px solid black' }}>Checklist Item</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        {checklistItems.map((item: any) => (
                                            <tr key={item.checklistItemId}>
                                                <td style={{ border: '1px solid black' }}>
                                                    <input
                                                        type="checkbox"
                                                        style={{ margin: '5px', transform: 'scale(1.5)' }}
                                                        checked={selectedChecklistItemIds.includes(item.checklistItemId)}
                                                        onChange={() => handleChecklistItemChange(item.checklistItemId)}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>{item.itemDescription}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <Button
                            className='usa-button'
                            onClick={handleSaveButton}
                            disabled={
                                (fileValue.length > 0 && (fileValue.some(file => !file.uploadTypeLkpcd) || fileValue.some(file => !file.mcoViewable))) ||
                                reviewDecision === '' || reviewDecision === null || reviewDecision === undefined ||
                                (reviewModalData?.organizationUnitCode === 'FR' && (subActivityNotes === '' || subActivityNotes === null || subActivityNotes === undefined)) ||
                                !checkAccess('review_page', 'FULL')
                            }
                            loading={loadingButton}
                        >
                            Save
                        </Button>
                        <Button
                            className='usa-button'
                            disabled={
                                (fileValue.length > 0 && (fileValue.some(file => !file.uploadTypeLkpcd) || fileValue.some(file => !file.mcoViewable))) ||
                                reviewDecision === '' || reviewDecision === null || reviewDecision === undefined ||
                                (reviewModalData?.organizationUnitCode === 'FR' && (subActivityNotes === '' || subActivityNotes === null || subActivityNotes === undefined)) ||
                                !checkAccess('review_page', 'FULL')
                            }
                            onClick={handleSubmitButton}
                            loading={loadingButton}
                        >
                            Submit
                        </Button>
                        <button
                            className='usa-button'
                            onClick={closeReviewModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="row1" style={{ marginTop: '10px' }}>
                        <div className="element" >
                            <p><b>Last Updated By : </b>{reviewModalData?.updatedBy}</p>
                        </div>
                        <div className="element" >
                            <p><b>Last Updated Date : </b>{formatDateAndTime(reviewModalData?.updatedDate)}</p>
                        </div>
                    </div>
                </Modal>

                <div className="row1">
                    <div className="element" >
                        <p><b>Last Updated By : </b>{recordData?.updated_by}</p>
                    </div>
                    <div className="element" >
                        <p><b>Last Updated Date : </b>{formatDateAndTime(recordData?.updated_date)}</p>
                    </div>
                </div>
            </div >
            <div>
                <Footer />
            </div>
        </>
    );
}