import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SigninLandingPage from "./pages/sign-options/SignInLandingPage";
import AdminDashboard from "./pages/adminDashboard/adminDashboard";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import UserSignInPage from '../src/pages/user-pages/user-sign-in'
import UserSignUpPage from '../src/pages/user-pages/user-sign-up'
// import '../node_modules/uswds/dist/css/uswds.min.css';
import "./styles/app.scss";
import ContractRepotPage from '../src/pages/contract-report/contract-report-home'
import DashboardPage from '../src/pages/dashboard/dashboard-home'
import ReferencePage from '../src/pages/reference/reference-home'
import RemindersPage from '../src/pages/reminders/reminders-home'
import UploadRepotPage from '../src/pages/upload-report/upload-report-home'
import AdhocOrNewOnDemandReport from "./pages/upload-report/adhoc-or-new-report-page";
import UserManagemnetPage from '../src/pages/user-management/user-management-home'
import '@inovua/reactdatagrid-enterprise/index.css'

import ContractRepotDetailsPage from '../src/pages/contract-report/contract-report-details'
import ReportReviewHistoryPage from '../src/pages/contract-report/contract-review-history-details'
import McoActionsPage from "./pages/contract-report/mco-actions-page";
import ReviewPage from "./pages/contract-report/state-review-page";
import SftpReportDetailsPage from "./pages/sftp-reports/sftp-report-details";
import SftpReportsHomePahe from "./pages/sftp-reports/sftp-uploaded-report-home";
import ReportCatalogDetails from "./pages/reference/catalog-report-details";
import UserManagementDetails from "./pages/user-management/user-management-details";
import { GlobalContextProvider } from "./GlobalContext";
import { AccessControlProvider } from '../src/utils/AccessControlContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export let savedFilters = {
  ReferencePage: null,
  ContractRepotPage: null,
  RemindersPage: null,
  UserManagemnetPage: null,
};

function App({ msalInstance }) {

  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <GlobalContextProvider>
          <AccessControlProvider>
            <main id="main">
              <div className="container-fluid">
                <div className="grid-row">
                  <div className="grid-col">
                    <ToastContainer theme="colored" />
                    <Route exact path="/"><SigninLandingPage /></Route>
                    <Route path="/sign-in" component={SigninLandingPage} />
                    <Route path="/user-sign-in" component={UserSignInPage} />
                    <Route path="/admin-dashboard" component={AdminDashboard} />
                    <Route path="/user-sign-up" component={UserSignUpPage} />
                    <Route path="/contact-report-invenroty" component={ContractRepotPage} />
                    <Route path="/reports-dashboard" component={DashboardPage} />
                    <Route path="/reference-data" component={ReferencePage} />
                    <Route path="/reminders-notifications" component={RemindersPage} />
                    <Route path="/upload-reports" component={UploadRepotPage} />
                    <Route path="/user-management" component={UserManagemnetPage} />
                    <Route path="/contract-report-details" component={ContractRepotDetailsPage} />
                    <Route path="/review-history-details" component={ReportReviewHistoryPage} />
                    <Route path="/adhoc-or-new-report" component={AdhocOrNewOnDemandReport} />
                    <Route path="/mco-actions" component={McoActionsPage} />
                    <Route path="/mco-review" component={ReviewPage} />
                    <Route path="/sftp-report-details" component={SftpReportDetailsPage} />
                    <Route path="/sftp-uploaded-reports" component={SftpReportsHomePahe} />
                    <Route path="/report-catalog-details" component={ReportCatalogDetails} />
                    <Route path="/user-management-details" component={UserManagementDetails} />
                  </div>
                </div>
              </div>
            </main>
          </AccessControlProvider>
        </GlobalContextProvider>
      </MsalProvider>
    </React.Fragment>
  );
}

export default App;
